import React, { useMemo, useState } from "react";
import { Button, Col, Form, Modal } from "react-bootstrap";

const ModalNewTrialMap = ({ show, close, handleSave, saving }) => {
	const [form, setForm] = useState({
		fm_name: "",
		fm_n_repetitions: 1,
		fm_n_treatments: 1,
	});

	const changeValue = (field, value) =>
		setForm((s) => ({ ...s, [field]: value }));

	const disableButton = useMemo(() => {
		const disabled = [
			!form.fm_name?.trim(),
			!(form.fm_n_repetitions > 0),
			!(form.fm_n_treatments > 0),
		];

		return disabled.some((v) => v);
	}, [form]);

	return (
		<Modal
			centered
			show={show}
			onHide={close}
		>
			<Modal.Header closeButton>
				<Modal.Title>New Field Map</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form.Group
					as={Col}
					className="mb-3"
				>
					<Form.Label>Name</Form.Label>
					<Form.Control
						onChange={(e) => {
							changeValue("fm_name", e.target.value);
						}}
						value={form.fm_name}
					/>
				</Form.Group>

				<Form.Group
					as={Col}
					className="mb-3"
				>
					<Form.Label>Number of treatment(s)</Form.Label>
					<Form.Control
						type="number"
						onChange={(e) => {
							changeValue("fm_n_treatments", Math.abs(Number(e.target.value)));
						}}
						value={form.fm_n_treatments}
					/>
				</Form.Group>

				<Form.Group
					as={Col}
					className="mb-3"
				>
					<Form.Label>Number of repetition(s)</Form.Label>
					<Form.Control
						type="number"
						onChange={(e) => {
							changeValue("fm_n_repetitions", Math.abs(Number(e.target.value)));
						}}
						value={form.fm_n_repetitions}
					/>
				</Form.Group>
			</Modal.Body>
			<Modal.Footer>
				<Button
					variant="success"
					disabled={disableButton || saving}
					onClick={() => handleSave(form)}
				>
					{saving ? "Saving..." : "Save"}
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default ModalNewTrialMap;
