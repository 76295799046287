import React from "react";
import Chart from "react-apexcharts";

const BarChart = ({
	series,
	categories,
	points = [],
	options: customOptions,
}) => {
	const options = {
		annotations: {
			points: points.map((p) => ({
				...p,
				x: p.y,
				y: p.x,
				label: { ...p.label, offsetY: 6 },
			})),
		},
		chart: {
			type: "bar",
			toolbar: {
				show: false,
			},
			zoom: {
				enabled: false,
			},
		},
		plotOptions: {
			bar: {
				horizontal: true,
				borderRadius: 4,
			},
		},
		stroke: {
			width: 2,
		},

		grid: {
			row: {
				colors: ["#fff", "#f2f2f2"],
			},
		},
		xaxis: {
			categories,
			tickPlacement: "on",
		},
		dataLabels: {
			enabled: true,
			formatter: function (val) {
				return val?.toFixed(2);
			},
			offsetY: 0,
			style: {
				fontSize: "12px",
			},
		},
		...customOptions,
	};

	return (
		<div
			className="w-100 d-flex"
			style={{ height: "45vh" }}
		>
			<div className="w-100">
				<Chart
					options={options}
					series={series}
					type="bar"
					width={"100%"}
					height={"100%"}
				/>
			</div>
		</div>
	);
};

export default BarChart;
