/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo } from "react";
import { Button, Col, Form, Spinner, Table } from "react-bootstrap";
import { toast } from "react-toastify";
import { FaFilePdf } from "react-icons/fa";
import { pdf } from "@react-pdf/renderer";
import { toPng } from "html-to-image";
import { saveAs } from "file-saver";

import { translateAsessmentType } from "../../pages/TrialAssessment";

import { capilizeCharAt0, formatDate, generateRandomColor } from "./util";

import Api from "../../services/api";

import DropdownButton from "../DropdownButton";
import { DataTitle } from "../ApplicationMap/styles";

import ColumnChart from "../ColumnChart";
import BarChart from "../BarChart";
import LineChart from "../LineChart";
import ScatterChart from "../ScatterChart";

import PDFSeverity from "./components/PDFSeverity";
import PDFVigorYield from "./components/PDFVigorYield";

import * as S from "./styles";

function DataVisualization(props) {
	const [tableContentDataVisu, setTableContentDataVisu] = useState(
		props.tableContent
	);
	const [assessment_id, setAssessment_id] = useState(props.assessment_id);
	const [chartData, setChartData] = useState();
	const [samples, setSamples] = useState([]);
	const [chartType, setChartType] = useState("column");
	const [exporting, setExporting] = useState(false);

	const [sortBy, setSortBy] = useState(
		["yield", "vigor"].includes(props.type) ? "ranking" : "treatment"
	);

	var treatments_count = props.treatments_count;
	var repetitions_count = props.repetitions_count;

	const updateTableContentDataVisu = (index, value) => {
		let newArr = [...tableContentDataVisu];
		newArr[index].treatment_valor = value;

		setTableContentDataVisu(newArr);
	};

	const colors = useMemo(
		() => Array.from({ length: treatments_count }, generateRandomColor),
		[treatments_count]
	);

	const [classificationType, setClassificationType] = useState("scottknott");
	const [showClassifications, setShowClassifications] = useState(true);

	const [assessmentInfo, setAssessmentInfo] = useState({
		alpha: "",
		cv: "",
		dms: "",
		gl: "",
		homogeneidade: "",
		normalidade: "",
		p_valor: "",
		qmres: "",
	});

	const createPDF = async () => {
		setExporting(true);

		let chartImage = "";
		let estimatedSeverityImage = "";

		const chartElement = document.getElementById("chart");
		if (chartElement) {
			const data = await toPng(chartElement, { backgroundColor: "white" });
			chartImage = data;
		}

		const estimatedSeverityElement =
			document.getElementById("estimated-severity");
		if (estimatedSeverityElement) {
			const data = await toPng(estimatedSeverityElement, {
				backgroundColor: "white",
			});
			estimatedSeverityImage = data;
		}

		const PDFObject = pdf();

		if (["severity", "target_spot"].includes(props.type)) {
			PDFObject.updateContainer(
				<PDFSeverity
					trial={props.trial}
					assessment={props.assessment}
					analysis={assessmentInfo}
					chartImage={chartImage}
					sortBy={sortBy}
					estimatedSeverityImage={estimatedSeverityImage}
					classification={classificationType}
				/>
			);
		}

		if (["yield", "vigor"].includes(props.type)) {
			PDFObject.updateContainer(
				<PDFVigorYield
					trial={props.trial}
					assessment={props.assessment}
					analysis={assessmentInfo}
					chartImage={chartImage}
					sortBy={sortBy}
					chartData={chartData}
					classification={classificationType}
				/>
			);
		}

		const fileName = `${props.trial?.name} - ${capilizeCharAt0(
			props.assessment.type
		)} (${formatDate(props.assessment.date)})`;

		const blob = await PDFObject.toBlob();
		saveAs(blob, `${fileName}.pdf`);
		setExporting(false);
	};

	const chartDataFactory = async () => {
		var chartData = [];

		if (assessment_id) {
			await Api.get(`/sample/report/${assessment_id}`)
				.then((res) => {
					for (let index = 0; index < treatments_count; index++) {
						const data = res.data.samples[`${index + 1}`];
						chartData.push({
							name: `T${index + 1}`,
							severity: data?.average || 0,
							blocks: data?.blocks || [],
							tukey: data?.tukey || "",
							duncan: data?.duncan || "",
							scottknott: data?.scottknott || "",
						});
						setAssessmentInfo({ ...res.data.assessment });
					}
				})
				.catch((err) => {
					console.log("err> ", err);
					toast.error("Error!");
				});

			setChartData(chartData);
		}
	};

	const handleCopyBlocks = () => {
		const lines = [
			`Treatment\tBlock\t${
				translateAsessmentType[props.type]
			}\tScottKnott\tDuncan\tTukey`,
		];

		const sorted =
			sortBy === "treatment"
				? chartData
				: [...chartData]?.sort(
						(a, b) => Number(b.severity) - Number(a.severity)
				  );

		sorted?.forEach((data) => {
			Object.entries(data.blocks).forEach(([nm, value]) => {
				const line = `${data.name}\t${nm}\t${Number(value?.toFixed(2))}\t${
					data.scottknott
				}\t${data.duncan}\t${data.tukey}`;
				lines.push(line);
			});
		});

		lines.push(
			``,
			`Alpha\t${assessmentInfo.alpha}`,
			`CV\t${assessmentInfo.cv}`,
			`DMSTukey\t${assessmentInfo.dms}`,
			`GL\t${assessmentInfo.gl}`,
			`Homogeneity\t${assessmentInfo.homogeneidade}`,
			`Normality\t${assessmentInfo.normalidade}`,
			`P Value\t${assessmentInfo.p_valor}`,
			`QMres\t${assessmentInfo.qmres}`
		);

		navigator.clipboard.writeText(lines.join("\n"));
		toast.success("Blocks copied to the clipboard!");
	};

	const handleCopyAverages = () => {
		const lines = [`Treatment\tAverage\tScottKnott\tDuncan\tTukey`];

		const sorted =
			sortBy === "treatment"
				? chartData
				: [...chartData]?.sort(
						(a, b) => Number(b.severity) - Number(a.severity)
				  );

		sorted?.forEach((data) => {
			const line = `${data.name}\t${Number(data.severity?.toFixed(2))}\t${
				data.scottknott
			}\t${data.duncan}\t${data.tukey}`;
			lines.push(line);
		});

		const {
			alpha = "",
			cv = "",
			dms = "",
			gl = "",
			homogeneidade = "",
			normalidade = "",
			p_valor = "",
			qmres = "",
		} = assessmentInfo;

		lines.push(
			``,
			`Alpha\t${alpha || ""}`,
			`CV\t${cv || ""}`,
			`DMSTukey\t${dms || ""}`,
			`GL\t${gl || ""}`,
			`Homogeneity\t${homogeneidade || ""}`,
			`Normality\t${normalidade || ""}`,
			`P Value\t${p_valor || ""}`,
			`QMres\t${qmres || ""}`
		);

		navigator.clipboard.writeText(lines.join("\n"));
		toast.success("Averages copied to the clipboard!");
	};

	const loadApplicationDataVisu = async () => {
		if (assessment_id) {
			await Api.get(`/sample/${assessment_id}`)
				.then((result) => {
					if (result.data.length === 0) {
						return setTableContentDataVisu(tablegenerator());
					}

					setSamples(result.data);
					result.data.forEach((element) => {
						let indexOfEl = tableContentDataVisu.findIndex(
							(i) => i.treatment_name === element.name
						);

						if (indexOfEl > -1) {
							updateTableContentDataVisu(indexOfEl, element.predict);
						}
					});
				})
				.catch((err) => {
					console.log("err> ", err);
					toast.error("Error!");
				});
		}
	};

	const sortedData = useMemo(() => {
		if (chartData?.length > 0) {
			const sorted =
				sortBy === "treatment"
					? chartData
					: [...chartData]?.sort(
							(a, b) => Number(b.severity) - Number(a.severity)
					  );

			const data = [];
			const categories = [];
			const points = [];

			for (const d of sorted) {
				categories.push(d.name);
				data.push(d.severity);

				if (showClassifications)
					points.push({
						x: d.name,
						y: d.severity,
						seriesIndex: 0,
						marker: {
							size: 0,
						},
						label: {
							borderColor: "#775DD0",
							offsetY: -12,
							style: {
								color: "#fff",
								background: "#775DD0",
							},
							text: d[classificationType] || "",
						},
					});
			}

			return {
				series: [
					{
						name: "Severity",
						data,
					},
				],
				categories,
				points,
			};
		}
		return {
			series: [],
			categories: [],
			points: [],
		};
	}, [chartData, sortBy, classificationType, showClassifications]);

	const tableContentRenderDataVisu = () => {
		const rows = [];
		let columns = [];

		tableContentDataVisu.forEach((element, index) => {
			const tratNumber =
				samples.find((s) => s.name === element.treatment_name)?.treatment || -1;

			const bg = colors[tratNumber - 1];

			if (index % treatments_count === 0) {
				rows.push(<tr>{columns}</tr>);
				columns = [];
			}

			const value = isNaN(element.treatment_valor)
				? 0
				: Number(element.treatment_valor || 0);

			columns.push(
				<td key={element.treatment_name}>
					<S.BlockContainer
						bg={bg}
						opacity={1}
					>
						<span>{element.treatment_name} </span>
						<span style={{ marginTop: "10px" }}> {value?.toFixed(2)}% </span>
					</S.BlockContainer>
				</td>
			);
		});
		if (columns.length) {
			rows.push(<tr>{columns}</tr>);
		}

		return rows;
	};

	const tablegenerator = () => {
		var result = [];
		let t_name = 0,
			aux = 0,
			x = 100;

		for (let index = 0; index < treatments_count * repetitions_count; index++) {
			if (aux < treatments_count) {
				aux = aux + 1;
			} else {
				aux = 1;
				x = x + 100;
			}
			t_name = x + aux;

			result.push({
				treatment_name: t_name.toString(),
				treatment_valor: "--",
			});
		}
		return result;
	};

	const renderChart = useMemo(() => {
		switch (chartType) {
			case "scatter":
				return (
					<ScatterChart
						series={sortedData.series}
						categories={sortedData.categories}
						points={sortedData.points}
					/>
				);
			case "line":
				return (
					<LineChart
						series={sortedData.series}
						categories={sortedData.categories}
						points={sortedData.points}
					/>
				);
			case "bar":
				return (
					<BarChart
						series={sortedData.series}
						categories={sortedData.categories}
						points={sortedData.points}
					/>
				);
			case "column":
				return (
					<ColumnChart
						series={sortedData.series}
						categories={sortedData.categories}
						points={sortedData.points}
					/>
				);
			default:
				return null;
		}
	}, [chartType, sortedData]);

	useEffect(() => {
		setTableContentDataVisu(tablegenerator());
	}, []);

	useEffect(() => {
		if (assessment_id !== props.assessment_id) {
			setAssessment_id(props.assessment_id);
		}
	}, [props.assessment_id]);

	useEffect(() => {
		loadApplicationDataVisu();
		chartDataFactory();
	}, [assessment_id]);

	if (["severity", "target_spot"].includes(props.type))
		return (
			<S.DataCardContainer>
				<Col
					className="w-100 d-flex justify-content-end pt-4"
					style={{ paddingRight: "3em" }}
				>
					<Button
						disabled={exporting}
						onClick={createPDF}
						className="d-flex align-items-center gap-2"
					>
						<FaFilePdf />
						{exporting ? "Exporting..." : "Export to PDF"}
					</Button>
				</Col>

				<S.DataCard className="mt-3 d-flex flex-row">
					<Col md={9}>
						<div className="p-4">
							<DataTitle>
								<span>{translateAsessmentType[props.type]}</span>
							</DataTitle>
							<Col className="d-flex gap-4">
								<Form.Group
									as={Col}
									md={2}
									className="d-flex flex-column justify-content-end"
								>
									<Form.Label className="mb-1">Chart type</Form.Label>
									<Form.Select
										value={chartType}
										onChange={(e) => setChartType(e.target.value)}
									>
										<option value={"column"}>Column</option>
										<option value={"bar"}>Bar</option>
										<option value={"line"}>Line</option>
										<option value={"scatter"}>Scatter</option>
									</Form.Select>
								</Form.Group>
								<Col
									md="auto"
									className="d-flex"
								>
									<Form.Group as={Col}>
										<Form.Check
											className="my-1"
											id="Show classifications"
											type="checkbox"
											checked={showClassifications}
											onChange={(e) => setShowClassifications(e.target.checked)}
											label="Show classifications"
										/>

										<Form.Select
											style={{ width: "auto" }}
											value={sortBy}
											onChange={(e) => setSortBy(e.target.value)}
										>
											<option value="treatment">Treatment</option>
											<option value="higher_value">
												Highest to lowest value
											</option>
										</Form.Select>
									</Form.Group>
								</Col>
								<Col
									md="auto"
									className="d-flex align-items-end"
								>
									{showClassifications ? (
										<Form.Select
											style={{ width: "auto" }}
											value={classificationType}
											onChange={(e) => setClassificationType(e.target.value)}
										>
											<option value="scottknott">ScottKnott</option>
											<option value="duncan">Duncan</option>
											<option value="tukey">Tukey</option>
										</Form.Select>
									) : null}
									<div>
										<DropdownButton
											onClick={{
												blocks: handleCopyBlocks,
												averages: handleCopyAverages,
											}}
										/>
									</div>
								</Col>
							</Col>
						</div>

						{chartData?.length > 1 ? (
							<Col
								id="chart"
								className="px-3 mb-3"
								style={{
									display: "flex",
									width: "100%",
								}}
							>
								{renderChart}
							</Col>
						) : (
							<Col className="d-flex w-100 h-100 justify-content-center align-items-center">
								<Spinner variant="success" />
							</Col>
						)}
					</Col>

					<Col
						md={3}
						className="p-4 d-flex flex-column justify-content-start align-items-center"
					>
						<DataTitle className="mb-3">Analysis</DataTitle>
						<Col>
							<p>
								<b>Alpha:</b> {assessmentInfo.alpha}
							</p>
							<p>
								<b>CV:</b> {assessmentInfo.cv}
							</p>
							<p>
								<b>DMSTukey:</b> {assessmentInfo.dms}
							</p>
							<p>
								<b>GL:</b> {assessmentInfo.gl}
							</p>
							<p>
								<b>Homogeneity:</b> {assessmentInfo.homogeneidade}
							</p>
							<p>
								<b>Normality:</b> {assessmentInfo.normalidade}
							</p>
							<p>
								<b>P Value:</b> {assessmentInfo.p_valor}
							</p>
							<p>
								<b>QMres:</b> {assessmentInfo.qmres}
							</p>
						</Col>
					</Col>
				</S.DataCard>
				<S.DataCard style={{ marginTop: "18px", alignItems: "center" }}>
					<DataTitle className="my-3">
						Estimated {translateAsessmentType[props.type]}
					</DataTitle>
					<S.CustomTable
						id="estimated-severity"
						className="mb-3"
					>
						<tbody>
							<tr>{tableContentRenderDataVisu()}</tr>
						</tbody>
					</S.CustomTable>{" "}
				</S.DataCard>
			</S.DataCardContainer>
		);

	if (["yield", "vigor"].includes(props.type))
		return (
			<S.DataCardContainer>
				<Col
					className="w-100 d-flex justify-content-end pt-4"
					style={{ paddingRight: "3em" }}
				>
					<Button
						disabled={exporting}
						onClick={createPDF}
						className="d-flex align-items-center gap-2"
					>
						<FaFilePdf />
						{exporting ? "Exporting..." : "Export to PDF"}
					</Button>
				</Col>

				<S.DataCard className="mt-3">
					<Col
						style={{ maxHeight: "112px" }}
						className="d-flex w-100 flex-column px-4 pt-4"
					>
						<DataTitle>{translateAsessmentType[props.type]}</DataTitle>
						<div>
							<Col className="d-flex mt-3">
								<Col md={2}>
									<Form.Select
										value={sortBy}
										id="sort-table-only"
										onChange={(e) => setSortBy(e.target.value)}
									>
										<option value="ranking">Ranking</option>
										<option value="treatment">Treatment</option>
									</Form.Select>
								</Col>
								<DropdownButton
									onClick={{
										blocks: handleCopyBlocks,
										averages: handleCopyAverages,
									}}
								/>
							</Col>
						</div>
					</Col>
					<Col className="d-flex w-100 px-4 pb-4">
						<Col
							md={7}
							style={{ overflow: "auto", maxHeight: "40vh" }}
						>
							<Table
								id="estimated-severity"
								striped
								hover
								style={{ borderCollapse: "collapse" }}
							>
								<thead>
									<tr
										style={{
											fontSize: "1.1em",
											textAlign: "center",
											verticalAlign: "middle",
											margin: "24px",
											position: "sticky",
											top: 0,
										}}
									>
										<th style={{ padding: "12px" }}>Treatment</th>
										<th style={{ padding: "12px" }}>Estimated value</th>
										<th style={{ padding: "12px" }}>ScottKnott</th>
										<th style={{ padding: "12px" }}>Duncan</th>
										<th style={{ padding: "12px" }}>Tukey</th>
									</tr>
								</thead>
								<tbody>
									{chartData?.length > 0
										? chartData
												?.sort((b, a) =>
													sortBy === "ranking"
														? Number(a.severity) - Number(b.severity)
														: Number(b.name.replace("T", "")) -
														  Number(a.name.replace("T", ""))
												)
												.map((s, idx) => (
													<tr
														key={`item-${s.name}`}
														style={{
															fontSize: "1.1em",
															textAlign: "center",
															verticalAlign: "middle",
															backgroundColor:
																idx % 2 === 0 ? "#f5f5f5" : "white",
														}}
													>
														<td style={{ height: "38px" }}>{s.name}</td>
														<td style={{ height: "38px" }}>
															{Number(s.severity || "0")?.toFixed(2)}
															{props.type === "yield" ? " kg/ha" : "%"}
														</td>
														<td style={{ height: "38px" }}>{s.scottknott}</td>
														<td style={{ height: "38px" }}>{s.duncan}</td>
														<td style={{ height: "38px" }}>{s.tukey}</td>
													</tr>
												))
										: null}
								</tbody>
							</Table>
						</Col>
						<Col
							md={5}
							className="px-5"
						>
							<div style={{ margin: "12px" }}>
								<Col
									className="mb-2"
									style={{
										width: "100%",
										textAlign: "center",
										fontWeight: "bold",
									}}
								>
									<DataTitle>Statistical analysis information</DataTitle>
								</Col>
								<p>
									<b>Alpha:</b> {assessmentInfo.alpha}
								</p>
								<p>
									<b>CV:</b> {assessmentInfo.cv}
								</p>
								<p>
									<b>DMSTukey:</b> {assessmentInfo.dms}
								</p>
								<p>
									<b>GL:</b> {assessmentInfo.gl}
								</p>
								<p>
									<b>Homogeneity:</b> {assessmentInfo.homogeneidade}
								</p>
								<p>
									<b>Normality:</b> {assessmentInfo.normalidade}
								</p>
								<p>
									<b>P Value:</b> {assessmentInfo.p_valor}
								</p>
								<p>
									<b>QMres:</b> {assessmentInfo.qmres}
								</p>
							</div>
						</Col>
					</Col>
				</S.DataCard>
				<S.DataCard className="mt-0 d-flex flex-row">
					<Col>
						<div className="p-4">
							<Col className="d-flex gap-4">
								<Form.Group
									as={Col}
									md={2}
									className="d-flex flex-column justify-content-end"
								>
									<Form.Label className="mb-1">Chart type</Form.Label>
									<Form.Select
										value={chartType}
										onChange={(e) => setChartType(e.target.value)}
									>
										<option value={"column"}>Column</option>
										<option value={"bar"}>Bar</option>
										<option value={"line"}>Line</option>
										<option value={"scatter"}>Scatter</option>
									</Form.Select>
								</Form.Group>
								<Col
									md="auto"
									className="d-flex"
								>
									<Form.Group as={Col}>
										<Form.Check
											className="my-1"
											id="Show classifications"
											type="checkbox"
											checked={showClassifications}
											onChange={(e) => setShowClassifications(e.target.checked)}
											label="Show classifications"
										/>

										<Form.Select
											value={sortBy}
											id="sort-chart-only"
											onChange={(e) => {
												setSortBy(e.target.value);

												setTimeout(() => {
													const elChart = document.getElementById("chart");
													elChart?.scrollIntoView({
														block: "end",
														inline: "end",
													});
												}, 10);
											}}
										>
											<option value="ranking">Ranking</option>
											<option value="treatment">Treatment</option>
										</Form.Select>
									</Form.Group>
								</Col>
								<Col
									md="auto"
									className="d-flex align-items-end"
								>
									{showClassifications ? (
										<Form.Select
											style={{ width: "auto" }}
											value={classificationType}
											onChange={(e) => setClassificationType(e.target.value)}
										>
											<option value="scottknott">ScottKnott</option>
											<option value="duncan">Duncan</option>
											<option value="tukey">Tukey</option>
										</Form.Select>
									) : null}
									<div>
										<DropdownButton
											onClick={{
												blocks: handleCopyBlocks,
												averages: handleCopyAverages,
											}}
										/>
									</div>
								</Col>
							</Col>
						</div>

						{chartData?.length > 1 ? (
							<Col
								id="chart"
								className="px-3 mb-3"
								style={{
									display: "flex",
									width: "100%",
								}}
							>
								{renderChart}
							</Col>
						) : (
							<Col className="d-flex w-100 h-100 justify-content-center align-items-center">
								<Spinner variant="success" />
							</Col>
						)}
					</Col>
				</S.DataCard>
			</S.DataCardContainer>
		);
	return null;
}

export default DataVisualization;
