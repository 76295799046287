import React from "react";
import Chart from "react-apexcharts";

const ColumnChart = ({ series, categories }) => {
	const options = {
		chart: {
			type: "bar",
			toolbar: {
				show: false,
			},
			zoom: {
				enabled: false,
			},
		},
		plotOptions: {
			bar: {
				borderRadius: 4,
				dataLabels: {
					position: "top",
				},
				distributed: true,
			},
		},
		stroke: {
			width: 2,
		},
		xaxis: {
			categories,
			tickPlacement: "on",
		},
		legend: {
			show: false,
		},
		dataLabels: {
			enabled: true,
			offsetY: -24,
			style: {
				fontSize: "14px",
				colors: ["#304758"],
			},
		},
		yaxis: {
			max: Math.max(...series[0].data) * 1.1,
			tickAmount: 3,
		},
	};

	return (
		<div
			className="w-100 d-flex"
			style={{ height: "100%" }}
		>
			<div className="w-100">
				<Chart
					options={options}
					series={series}
					type="bar"
					width={"100%"}
					height={"100%"}
				/>
			</div>
		</div>
	);
};

export default ColumnChart;
