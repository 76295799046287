import React, { useState, useEffect } from "react";
import { FaTrash } from "react-icons/fa";
import { toast } from "react-toastify";
import { Button, Col, Form, Spinner } from "react-bootstrap";

import Api from "../../services/api";

import ModalNewUser from "./components/ModalNewUser";

import ConfirmacaoAsync from "../../components/ConfirmacaoAsync";

import { ControllerCard } from "../Trials/styles";

import * as S from "./styles";

const Users = () => {
	const [search, setSearch] = useState("");

	const [showModalNewUser, setShowModalNewUser] = useState(false);
	const [loadingInformation, setLoadingInformation] = useState(true);

	const [userList, setUserList] = useState([]);

	const handleSaveNewUser = async (newUser) => {
		await Api.post("/usuario", newUser)
			.then((res) => {
				setUserList((s) => [
					...s,
					{
						...newUser,
						id: res.data.id,
					},
				]);
				setShowModalNewUser(false);

				toast.success("User created successfully!");
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const handleDelete = async (item) => {
		const confirmation = await ConfirmacaoAsync({
			title: "Delete this user?",
			message: "Are you sure want to delete this user?",
			variant: "danger",
		});

		if (confirmation) {
			try {
				await Api.delete("/usuario", {
					data: { id: item.id },
				});
				setUserList((s) => s.filter((u) => u.id !== item.id));

				toast.success("User deleted successfully!");
			} catch (error) {
				console.log(error);
				toast.error("There was an error trying to delete the selected user.");
			}
		}
	};

	useEffect(() => {
		const fetch = async () => {
			setLoadingInformation(true);
			await Api.get("/usuario")
				.then((res) => {
					setUserList(res.data);
				})
				.catch((err) => {
					console.log("err> ", err);
					setUserList([]);
				})
				.finally(() => {
					setLoadingInformation(false);
				});
		};
		fetch();
	}, []);

	return (
		<S.Container>
			<S.ControllerContainer>
				<Col className="d-flex w-50 align-items-center">
					<Col
						className="d-flex flex-column w-100"
						md="auto"
					>
						<Col className="w-100 d-flex mb-2">
							<Button
								variant="success"
								onClick={() => setShowModalNewUser(true)}
							>
								{`New User +`}
							</Button>
						</Col>

						<Col md={12}>
							<Form.Control
								placeholder={"Search by login..."}
								onChange={(e) => {
									setSearch(e.target.value);
								}}
								value={search}
							/>
						</Col>
					</Col>
				</Col>
				<Col className="d-flex w-50 justify-content-end align-items-center">
					<ControllerCard>
						<span>{loadingInformation ? "-" : userList.length}</span>
						<div className="subtitle"> Users </div>
					</ControllerCard>
				</Col>
			</S.ControllerContainer>

			{loadingInformation ? (
				<Col className="mt-5 p-5">
					<Spinner
						animation="border"
						variant="success"
					/>
				</Col>
			) : (
				<S.TableContainer>
					<S.CustomTable>
						<thead>
							<tr>
								<th className="text-start">
									<span className="mx-5">Login</span>
								</th>
								<th />
							</tr>
						</thead>
						<tbody>
							{userList
								.filter(
									(t) =>
										t.login.toUpperCase().indexOf(search.toUpperCase()) !== -1
								)
								.map((item) => (
									<tr key={`item-user-${item.id}`}>
										<td className="text-start">
											<span className="mx-5">{item.login}</span>
										</td>
										<td>
											<Button
												size="sm"
												variant="danger"
												onClick={(e) => {
													e.stopPropagation();
													handleDelete(item);
												}}
											>
												<FaTrash color="white" />
											</Button>
										</td>
									</tr>
								))}
						</tbody>
					</S.CustomTable>
				</S.TableContainer>
			)}

			{!showModalNewUser ? null : (
				<ModalNewUser
					show
					close={() => setShowModalNewUser(false)}
					handleSave={(newUser) => handleSaveNewUser(newUser)}
				/>
			)}
		</S.Container>
	);
};

export default Users;
