import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Alert, Button, Col, Form, Spinner } from "react-bootstrap";

import Api from "../../services/api";

import BG from "../../assets/login_background.jpg";
import Logotipo from "../../assets/logo.svg";

import * as S from "./styles";

async function loginUser(credentials) {
	return Api.post("/login", credentials).then((response) => {
		return response.data.token;
	});
}

function Login() {
	const history = useHistory();

	const [username, setUserName] = useState();
	const [password, setPassword] = useState();
	const [loggingIn, setLoggingIn] = useState(false);
	const [remember, setRemember] = useState(false);
	const [error, setError] = useState(false);

	const handleSubmit = async (e) => {
		e.preventDefault();
		setLoggingIn(true);
		try {
			const token = await loginUser({
				login: username,
				password: password,
			});
			localStorage.setItem("rememberLogin", remember);
			localStorage.setItem("token", token);
			history.push("/");
		} catch (error) {
			setError(true);
		}
		setLoggingIn(false);
	};

	useEffect(() => {
		const rememberLoginStatus = localStorage.getItem("rememberLogin");
		const token = localStorage.getItem("token");

		if (!!rememberLoginStatus && !!token) {
			history.push("/");
		}
	}, [history]);

	return (
		<S.Container>
			<S.Background src={BG} />
			<S.Content>
				<S.LogoContainer>
					<S.Logotipo src={Logotipo} />
				</S.LogoContainer>
				<Form
					className="w-100"
					onSubmit={handleSubmit}
				>
					<Form.Group
						as={Col}
						className="mb-4"
					>
						<Form.Label>Username</Form.Label>
						<Form.Control
							value={username}
							onChange={(e) => {
								setUserName(e.target.value);
								setError(false);
							}}
						/>
					</Form.Group>
					<Form.Group
						as={Col}
						className="mb-1"
					>
						<Form.Label>Password</Form.Label>
						<Form.Control
							type="password"
							value={password}
							onChange={(e) => {
								setPassword(e.target.value);
								setError(false);
							}}
						/>
					</Form.Group>

					<Form.Group
						as={Col}
						className="mb-4"
					>
						<Form.Check
							id="remember"
							label="Remember me"
							value={remember}
							onChange={(e) => setRemember(e.target.checked)}
						/>
					</Form.Group>

					{error ? (
						<Alert variant="danger">
							There was an error on login. Check the credentials.
						</Alert>
					) : null}

					<Col
						className="mt-5"
						md={12}
					>
						<Button
							variant="success"
							className="w-100"
							type="submit"
						>
							{loggingIn ? (
								<Col className="d-flex justify-content-center align-items-center gap-1">
									Logging in...{" "}
									<Spinner
										size="sm"
										animation="border"
										variant="light"
									/>
								</Col>
							) : (
								"Login"
							)}
						</Button>
					</Col>
				</Form>
			</S.Content>
		</S.Container>
	);
}

export default Login;
