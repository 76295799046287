/**
 * Create sub-arrays from any array, given an integer number which represents the length of resulting arrays
 * @param list any non-empty array of any elements
 * @param elementsPerSubArray the number of elements per sub-array
 * @returns an array of sub-arrays, where every sub-array has length <= ```elementsPerSubArray```
 */
export const encapsulateArray = (list, elementsPerSubArray) => {
  const matrix = [];
  let i;
  let k;

  for (i = 0, k = -1; i < list.length; i++) {
    if (i % elementsPerSubArray === 0) {
      k++;
      matrix[k] = [];
    }

    matrix[k].push(list[i] || "-");
  }

  return matrix;
};

export function range(min, max) {
  var len = max - min + 1;
  var arr = new Array(len);
  for (var i = 0; i < len; i++) {
    arr[i] = min + i;
  }
  return arr;
}
