import { Col } from "react-bootstrap";
import styled from "styled-components";

export const Header = styled.div`
  display: flex;

  border: 1px solid #c1c1c1;
  border-radius: 0.5em;
 
  padding: 1em;

  h5 {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

export const ChecklistContainer = styled(Col)` 
  border: 1px solid #c1c1c1;
  border-radius: 0.5em;  
`;

export const KonvaContainer = styled.div`
  display: flex;
  flex: 1;
  
  max-height: calc(100%); 
  max-width: calc(100% - 254px);
 
`;