import { useEffect, useState, useMemo } from "react";
import api from "../../services/api";
import { Col, Form, OverlayTrigger, Popover, Spinner } from "react-bootstrap";

import BarChart from "./components/BarChart";
import ColumnChart from "./components/ColumnChart";
import DonutChart from "./components/DonutChart";
import { DataTitle } from "../../components/ApplicationMap/styles";

import * as S from "./styles";

const mapTypes = {
	severity: "Severity",
	vigor: "Vigor",
	target_spot: "Target Spot",
	yield: "Yield",
};

const filterProperties = (obj, keysToKeep) => {
	const filteredObject = {};
	keysToKeep.forEach((key) => {
		if (obj.hasOwnProperty(key)) {
			filteredObject[key] = obj[key];
		}
	});
	return filteredObject;
};

const Home = () => {
	const [data, setData] = useState({
		rgb: {},
		multispectral: {},
	});

	const [loading, setLoading] = useState(false);

	const [chartType, setChartType] = useState({
		rgb: "column",
		multispectral: "column",
	});

	useEffect(() => {
		const fetch = async () => {
			setLoading(true);
			try {
				const { data: _data } = await api.get("/metrics");

				setData(_data);

				const savedChartType = JSON.parse(
					localStorage.getItem("homeChartConfig") || "{}"
				);
				if ("rgb" in savedChartType && "multispectral" in savedChartType) {
					setChartType(savedChartType);
				}
			} catch (error) {}
			setLoading(false);
		};
		fetch();
	}, []);

	const renderChartByType = (type, series, categories) => {
		switch (type) {
			case "column":
				return (
					<ColumnChart
						series={series}
						categories={categories}
					/>
				);
			case "bar":
				return (
					<BarChart
						series={series}
						categories={categories}
					/>
				);
			case "donut":
				return (
					<DonutChart
						series={series}
						categories={categories}
					/>
				);
			default:
				return null;
		}
	};

	const renderRGBChart = useMemo(() => {
		const filteredData = filterProperties(data.rgb, [
			"severity",
			"vigor",
			"target_spot",
			"yield",
		]);

		const categories = Object.keys(filteredData).map((t) => mapTypes[t]);

		const series = [
			{
				name: "Nº of Assessments",
				data: Object.values(filteredData),
			},
		];

		return renderChartByType(chartType.rgb, series, categories);
	}, [chartType, data]);

	const renderMultispectralChart = useMemo(() => {
		const filteredData = filterProperties(data.multispectral, [
			"severity",
			"vigor",
			"yield",
		]);

		const categories = Object.keys(filteredData).map((t) => mapTypes[t]);

		const series = [
			{
				name: "Nº of Assessments",
				data: Object.values(data.multispectral),
			},
		];

		return renderChartByType(chartType.multispectral, series, categories);
	}, [chartType, data]);

	return (
		<S.Container>
			{loading ? (
				<Col className="w-100 h-100 d-flex justify-content-center align-items-center">
					<Spinner variant="success" />
				</Col>
			) : (
				<Col>
					<Col
						className="d-flex justify-content-center"
						style={{ marginBottom: "5em" }}
					>
						<h2>
							<b>Dashboard</b>
						</h2>
					</Col>
					<Col
						className="mt-3 d-flex gap-2"
						style={{ height: "72vh" }}
					>
						<S.ChartContainer>
							<DataTitle className="my-2">Nº of Assessments - RGB</DataTitle>
							<S.ChartWrapper>
								<S.IconContainer>
									<OverlayTrigger
										trigger={["click"]}
										placement="bottom"
										overlay={(props) => (
											<Popover
												id="popover-rgb"
												{...props}
											>
												<Popover.Header>Settings</Popover.Header>
												<Popover.Body>
													<Form.Group>
														<Form.Label>Chart Type</Form.Label>
														<Form.Control
															as="select"
															value={chartType.rgb}
															onChange={(e) =>
																setChartType((s) => {
																	const newData = { ...s, rgb: e.target.value };

																	localStorage.setItem(
																		"homeChartConfig",
																		JSON.stringify(newData)
																	);

																	return newData;
																})
															}
														>
															<option value="column">Column</option>
															<option value="bar">Bar</option>
															<option value="donut">Donut</option>
														</Form.Control>
													</Form.Group>
												</Popover.Body>
											</Popover>
										)}
										rootClose
									>
										<Col>
											<S.IconChart />
										</Col>
									</OverlayTrigger>
								</S.IconContainer>

								{renderRGBChart}
							</S.ChartWrapper>
						</S.ChartContainer>
						<S.ChartContainer>
							<DataTitle className="my-2">
								Nº of Assessments - Multispectral
							</DataTitle>
							<S.ChartWrapper>
								<S.IconContainer>
									<OverlayTrigger
										trigger={["click"]}
										placement="bottom"
										overlay={(p) => (
											<Popover
												id="popover-multispectral"
												{...p}
											>
												<Popover.Header>Settings</Popover.Header>
												<Popover.Body>
													<Form.Group>
														<Form.Label>Chart Type</Form.Label>
														<Form.Control
															as="select"
															value={chartType.multispectral}
															onChange={(e) =>
																setChartType((s) => {
																	const newData = {
																		...s,
																		multispectral: e.target.value,
																	};

																	localStorage.setItem(
																		"homeChartConfig",
																		JSON.stringify(newData)
																	);

																	return newData;
																})
															}
														>
															<option value="column">Column</option>
															<option value="bar">Bar</option>
															<option value="donut">Donut</option>
														</Form.Control>
													</Form.Group>
												</Popover.Body>
											</Popover>
										)}
										rootClose
									>
										<Col>
											<S.IconChart />
										</Col>
									</OverlayTrigger>
								</S.IconContainer>
								{renderMultispectralChart}
							</S.ChartWrapper>
						</S.ChartContainer>
					</Col>
				</Col>
			)}
		</S.Container>
	);
};

export default Home;
