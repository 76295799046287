import styled from "styled-components";
import { Link } from "react-router-dom";
import { flexCenter } from "../../pages/Login/styles";
import { ReactSVG } from "react-svg";

export const Container = styled.div`
  animation: 0.7s fade-in;
  width: 100%;
  height: 100%;
  background-color: white;
  border-right: 1px solid #e0e0e0;

  ${flexCenter}
  flex-direction: column; 
`;

export const SidebarList = styled.div` 
  list-style: none;
  padding: 1em 0;
  width: 100%; 
  flex: 1;
  flex-direction: column;
  gap: 1em;
  
  `;

export const SidebarListItem = styled(Link)` 
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  text-Decoration: none;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  padding: 1em;  

  border-left: ${({ selected }) => selected ? "6px solid var(--fmc-red)" : '0px'};

  transition: border 0.2s ease-in-out;
`;


export const LogoContainer = styled.div` 
  ${flexCenter};
  width: 100%;
  height: 120px;
  border-bottom: 1px solid #e0e0e0;
  padding: 0.1em 0.5em;
`;

export const Logo = styled.img` 
  height: 70%;
`;

export const LogoutContainer = styled.div`
  ${flexCenter};
  justify-content: flex-end;
  width: 100%;
  border-top: 1px solid #e0e0e0;
  padding: 1em;
`;

export const IconContainer = styled.div`
  ${flexCenter}
  margin-right: 0.5em;
  width: 24px;
  height: 24px; 
  object-fit: contain;
`;

export const IconStyle = styled(ReactSVG)` 
  width: 100% !important ;
  height: 100%  !important; 
  --icon-color: ${({ color }) => color}; 
  path { 
    fill: var(--icon-color); 
  }
  path:nth-child(2n) { 
    stroke: var(--icon-color); 
  }
  rect {
    stroke: var(--icon-color); 
  }
  circle {
    stroke: var(--icon-color); 
  }
`; 