import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Button, Col, Form, Spinner } from "react-bootstrap";

import Api from "../../services/api";

import * as S from './styles'

function ApplicationMap(props) {
  const [tableContentAppMap, setTableContentAppMap] = useState(
    props.tableContent
  );

  const [saving, setSaving] = useState(false)
  const [fieldMapList, setFieldMapList] = useState([]);
  const [loadingDefaultFieldMap, setLoadingDefaultFieldMap] = useState(false);

  var trial_id = props.trial_id;
  var treatments_count = props.treatments_count;
  var repetitions_count = props.repetitions_count;


  const loadApplicationMap = async () => {
    await Api.get(`/trialmap/${trial_id}`).then(async (result) => {
      // Loading the field map list based on the number of treatments/repetitions
      const { data } = await Api.get("/field-map", {
        params: {
          fm_n_treatments: treatments_count,
          fm_n_repetitions: repetitions_count,
        },
      });
      setFieldMapList(data);

      result.data.forEach((element) => {
        let indexOfEl = tableContentAppMap.findIndex(
          (i) => i.treatment_name === element.name
        );
        if (indexOfEl > -1) {
          updateTableContentAppMap(indexOfEl, element.treatment);
        }
      });
    });
  };

  const updateTableContentAppMap = (index, value) => {
    let newArr = [...tableContentAppMap];
    newArr[index].treatment_valor = value;

    setTableContentAppMap(newArr);
  };

  const loadDefaultFieldMap = async (id) => {
    setLoadingDefaultFieldMap(true);
    try {
      const { data } = await Api.get(`/field-map/detail/${id}`);

      data.forEach((f, idx) => updateTableContentAppMap(idx, f.fi_treatment));
    } catch (error) {
      toast.error("There was an error loading the field map details.");
    }
    setLoadingDefaultFieldMap(false);
  };

  const tableContentRenderAppMap = () => {
    const rows = [];
    let columns = [];
    try {
      tableContentAppMap.forEach((element, index) => {
        if (index % treatments_count === 0) {
          rows.push(<tr>{columns}</tr>);
          columns = [];
        }
        columns.push(
          <S.TD key={element.treatment_name} >
            <Col >
              <div> {element.treatment_name} </div>
              <Form.Control
                size="sm"
                className='mt-1 text-center'
                name="table-input-108"
                onChange={(e) => {
                  if (Number.isFinite(+e.target.value)) {
                    updateTableContentAppMap(index, e.target.value);
                  }
                }}
                value={element.treatment_valor}
              />
            </Col>
          </S.TD>
        );
      });
      if (columns.length) {
        rows.push(<tr>{columns}</tr>);
      }
    } catch (error) {
      console.log(error);
    }

    return rows;
  };

  const handleSaveAppMap = async () => {
    let _trial_id = trial_id;
    let toSave = [];

    toSave = tableContentAppMap.map((element) => {
      return {
        trial_id: _trial_id,
        x_position: 25,
        y_position: 25,
        treatment: element.treatment_valor,
        name: element.treatment_name,
      };
    });

    let promises = [];

    promises = toSave.map(async (item) => {
      return Api.post("/trialmap", item);
    });

    setSaving(true)
    await Promise.all(promises)
      .then(() => {
        toast.success("Application map saved!");
        if (!!props?.goToAssessment) {
          props?.goToAssessment();
        }
      })
      .catch((err) => {
        toast.error(`Error! ${err.message}`);
        console.log(err);
      }).finally(() => {

        setSaving(false)
      })
  };

  useEffect(() => {
    loadApplicationMap();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Col className='d-flex align-items-center flex-column'>
      <S.Container
      >
        <S.DataTitle>
          Estimated Soybean Rust Severity
        </S.DataTitle>
        <S.SelectContainer>
          <label>Default field map:</label>
          <Form.Select
            size='sm'
            disabled={fieldMapList.length < 1 || loadingDefaultFieldMap}
            className="textInput"
            style={{ width: "auto", marginLeft: "12px" }}
            onChange={(e) => {
              if (Number(e.target.value) > 0) {
                loadDefaultFieldMap(Number(e.target.value));
              }
            }}
          >
            <option>Select...</option>
            {fieldMapList?.map((fm) => (
              <option key={fm.fm_id} value={fm.fm_id}>
                {fm.fm_name}
              </option>
            ))}
          </Form.Select>
        </S.SelectContainer>
        <S.ContainerTable >

          <S.CustomTable>
            <tbody>
              <tr>{tableContentRenderAppMap()}</tr>
            </tbody>
          </S.CustomTable>
        </S.ContainerTable>
      </S.Container>
      <Button
        variant='success'
        onClick={handleSaveAppMap}
      >
        {saving ? <Col className="d-flex justify-content-center align-items-center gap-1">
          Saving...{" "}
          <Spinner
            size="sm"
            animation="border"
            variant="light"
          />
        </Col> : 'Save application map'}
      </Button>
    </Col>
  );
}

export default ApplicationMap;
