import React, { useRef } from "react";
import { useOutsideClick } from "./useOutsideClick";

import { Button } from "react-bootstrap";

import "./styles.css";

const DropdownButton = ({ onClick: handleClick }) => {
  const dropdownRef = useRef(null);
  const [isActive, setIsActive] = useOutsideClick(dropdownRef, false);
  const onClick = () => setIsActive(!isActive);
  return (
    <div className="dropdown__container">
      {/* Dropdown Button */}
      <div className="dropdown__btn" onClick={onClick}>
        <Button
          variant='success'
          className='mx-3'
          onClick={() => { }}
        >
          Copy
        </Button>
      </div>

      {/* Dropdown Content */}
      <div
        ref={dropdownRef}
        className={`dropdown__content ${isActive ? "active" : "inactive"}`}
      >
        <div className="dropdown__info">
          <ul>
            <li
              onClick={() => {
                handleClick.blocks();
                onClick();
              }}
            >
              <span>Blocks</span>
            </li>
            <li
              onClick={() => {
                handleClick.averages();
                onClick();
              }}
            >
              <span>Averages</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default DropdownButton;
