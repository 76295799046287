import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-wrap: nowrap;
  
  height: fit-content;

  margin: 2em;
  padding: 2em;
  background: #ffffff;
  box-shadow: 0px 2px 10px rgb(0 0 0 / 20%);
  border-radius: 0.5em;
  
`;

export const ContainerTable = styled.div` 
  overflow: auto;
  max-width: 52vw;
  max-height: 52vh;
  padding: 2em;
`;

export const TD = styled.td`

  text-align: center;
  vertical-align: middle;
  font-family: Archivo;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 15px;

  color: #767676;

  > div { 
    width: 60px !important;
    max-width: 60px;
    height: 50px !important;
    max-height: 50px; 
  }

`;

export const CustomTable = styled.table`
  border-collapse: collapse;
  border-spacing: 0;  
  td {

    border: 1px solid #c6c6c6;
    border-style: solid;
    border-width: 1px;
      
    overflow: hidden;
    padding: 10px 5px;
    word-break: normal; 
  }
`;

export const DataTitle = styled.span` 
  font-weight: bold;
  font-size: 18px;
  line-height: 20px;
  color: #767676; 
`;

export const SelectContainer = styled.div`
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  select {
    margin: 6px 0 !important;
  }
`;