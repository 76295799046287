import React from "react";
import { Modal, Button } from "react-bootstrap";
import { v4 } from "uuid";
import { create } from "react-modal-promise";

const ConfirmarAsync = ({
	isOpen,
	title,
	message,
	loading,
	onResolve,
	variant,
	yesOrNo,
	closeOnly,
	required,
}) => {
	const confirmar = () => {
		if (typeof onResolve === "function") onResolve(true);
	};
	const cancelar = () => {
		if (typeof onResolve === "function") onResolve(false);
	};
	return (
		<Modal
			backdropClassName="confirm-backdrop"
			show={isOpen}
			onHide={() => (required ? null : cancelar())}
			centered
		>
			{title && (
				<Modal.Header closeButton={!required}>
					<Modal.Title>{title}</Modal.Title>
				</Modal.Header>
			)}
			<Modal.Body>
				{typeof message === "string"
					? message
					: message?.map((tra) => <p key={v4()}>{tra}</p>)}
			</Modal.Body>
			<Modal.Footer>
				{!closeOnly ? (
					<>
						<Button
							variant="secondary"
							onClick={cancelar}
						>
							{yesOrNo ? "No" : "Cancel"}
						</Button>
						<Button
							variant={variant || "success"}
							onClick={confirmar}
						>
							{loading ? "Loading..." : yesOrNo ? "Yes" : "Confirm"}
						</Button>
					</>
				) : (
					<Button
						variant="secondary"
						onClick={cancelar}
					>
						{required ? "Cancel" : "Close"}
					</Button>
				)}
			</Modal.Footer>
		</Modal>
	);
};

export default create(ConfirmarAsync);
