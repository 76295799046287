import React, { useState, useEffect, useMemo } from "react";
import { Button, Col, Form, Spinner } from "react-bootstrap";
import Select from "react-select";
import { toPng } from "html-to-image";
import { pdf } from "@react-pdf/renderer";
import { FaFilePdf } from "react-icons/fa";
import { saveAs } from "file-saver";

import Api from "../../services/api";

import ScatterChart from "../ScatterChart";
import LineChart from "../LineChart";
import BarChart from "../BarChart";
import ColumnChart from "../ColumnChart";
import MultiLineChart from "../MultiLineChart";
import PDFExport from "./components/PDFExport";

import { DataCard, DataCardContainer } from "../DataVisualization/styles";
import { DataTitle } from "../ApplicationMap/styles";

const labelize = (keys) =>
	keys?.map((i) => ({
		label: `T${i}`,
		value: i,
	})) || [];

function AUDPC({ trial }) {
	const [exporting, setExporting] = useState(false);
	const [loading, setLoading] = useState(true);

	const [selected, setSelected] = useState([]);
	const [audpc, setAudpc] = useState({});
	const [max, setMax] = useState(100);
	const [diseaseProgressCurve, setDiseaseProgressCurve] = useState({});

	const [audpcChartType, setAudpcChartType] = useState("column");

	useEffect(() => {
		const fetch = async () => {
			setLoading(true);
			await Api.get(`/assessment/audpc/${trial.id}`)
				.then(async (res) => {
					setAudpc(res.data.audpc);
					setDiseaseProgressCurve(res.data.diseaseProgressCurve);
					const treatments = Object.keys(res.data.diseaseProgressCurve);
					setSelected(labelize(treatments));
					setMax(res.data.max > 100 ? res.data.max : 100);
				})
				.catch((err) => {
					console.log("Err > ", err);
				})
				.finally(() => {
					setLoading(false);
				});
		};
		fetch();
	}, [trial.id]);

	const createPDF = async () => {
		setExporting(true);

		let chartAUDPCImage = "";
		let chartDiseaseImage = "";

		const audpcElement = document.getElementById("audpc-chart");
		if (audpcElement) {
			const data = await toPng(audpcElement, { backgroundColor: "white" });
			chartAUDPCImage = data;
		}

		const diseaseElement = document.getElementById("disease-chart");
		if (diseaseElement) {
			const data = await toPng(diseaseElement, {
				backgroundColor: "white",
			});
			chartDiseaseImage = data;
		}

		const PDFObject = pdf();

		PDFObject.updateContainer(
			<PDFExport
				trial={trial}
				selectedTreatments={selected.map((s) => s.label)}
				chartAUDPCImage={chartAUDPCImage}
				chartDiseaseImage={chartDiseaseImage}
			/>
		);

		const fileName = `${trial?.name} - AUDPC`;

		const blob = await PDFObject.toBlob();
		saveAs(blob, `${fileName}.pdf`);
		setExporting(false);
	};

	const renderAUDPCChart = useMemo(() => {
		if (!audpc?.length) return null;
		const series = [
			{
				name: "AUDPC",
				data: audpc.map((data) => data.severity),
			},
		];

		const categories = audpc.map((data) => `T${data.name}`);

		switch (audpcChartType) {
			case "scatter":
				return (
					<ScatterChart
						series={series}
						categories={categories}
						points={[]}
						max={max}
					/>
				);
			case "line":
				return (
					<LineChart
						series={series}
						categories={categories}
						points={[]}
						max={max}
					/>
				);
			case "bar":
				return (
					<BarChart
						series={series}
						categories={categories}
						points={[]}
						max={max}
					/>
				);
			case "column":
				return (
					<ColumnChart
						series={series}
						categories={categories}
						points={[]}
						max={max}
					/>
				);
			default:
				return null;
		}
	}, [audpc, audpcChartType, max]);

	const renderDiseaseChart = useMemo(() => {
		const selectedTreatments = selected.map((s) => s.value);

		if (!selectedTreatments.length)
			return (
				<Col className="w-100 h-100 d-flex justify-content-center align-items-center">
					<DataTitle>
						Select at least one treatment to create the chart
					</DataTitle>
				</Col>
			);

		return (
			<MultiLineChart
				series={Object.entries(diseaseProgressCurve)
					.filter(([t]) => selectedTreatments?.includes(t))
					.map(([treatment, data]) => ({
						name: `T${treatment}`,
						data: data.map((d) => ({
							x: d.date,
							y: d.mean,
						})),
					}))}
				categories={Object.values(diseaseProgressCurve)[0]?.map(
					(data) => data.date
				)}
				points={[]}
			/>
		);
	}, [diseaseProgressCurve, selected]);

	if (loading)
		return (
			<DataCardContainer>
				<Col className="w-100 h-100 d-flex justify-content-center align-items-center">
					<Spinner variant="success" />
				</Col>
			</DataCardContainer>
		);

	return (
		<DataCardContainer>
			<Col
				className="w-100 d-flex justify-content-end pt-4"
				style={{ paddingRight: "3em" }}
			>
				<Button
					disabled={exporting}
					onClick={createPDF}
					className="d-flex align-items-center gap-2"
				>
					<FaFilePdf />
					{exporting ? "Exporting..." : "Export to PDF"}
				</Button>
			</Col>

			<DataCard className="mb-0 mt-3 p-3">
				<DataTitle>AUDPC</DataTitle>
				<Form.Group
					as={Col}
					md={2}
					className="d-flex mt-2 flex-column justify-content-end"
				>
					<Form.Label className="mb-1">Chart type</Form.Label>
					<Form.Select
						value={audpcChartType}
						onChange={(e) => setAudpcChartType(e.target.value)}
					>
						<option value={"column"}>Column</option>
						<option value={"bar"}>Bar</option>
						<option value={"line"}>Line</option>
						<option value={"scatter"}>Scatter</option>
					</Form.Select>
				</Form.Group>
				<Col
					id="audpc-chart"
					style={{ width: "100%", height: "40vh" }}
				>
					{renderAUDPCChart}
				</Col>
			</DataCard>
			<DataCard className="p-3">
				<DataTitle>Disease Progress Curve</DataTitle>

				<Form.Group
					as={Col}
					md={5}
					className="mt-2 mb-3"
				>
					<Form.Label>Treatment</Form.Label>
					<Select
						isMulti
						name="treatments"
						options={[
							{ label: "Select all", value: undefined },
							...labelize(Object.keys(diseaseProgressCurve)),
						]}
						value={selected}
						onChange={(opts, current) => {
							if (current?.option?.label === "Select all") {
								setSelected(labelize(Object.keys(diseaseProgressCurve)));
							} else {
								setSelected(opts);
							}
						}}
					/>
				</Form.Group>

				<Col
					id="disease-chart"
					style={{ width: "100%", minHeight: "40vh" }}
				>
					{renderDiseaseChart}
				</Col>
			</DataCard>
		</DataCardContainer>
	);
}

export default AUDPC;
