import styled, { css } from 'styled-components';


export const flexCenter = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Background = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.5; 
`;

export const Container = styled.div`
  grid-column: 1/3;
  position: relative;
  ${flexCenter}
  flex-direction: column;
  width: 100%; 
  height: 100vh;

  background: black;
  overflow: hidden;

`;

export const Content = styled.div`
  width: 400px;
  height: 550px; 
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  background: white;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  padding: 2em;

  position: fixed;

  animation: 0.7s fade-in;

  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

export const LogoContainer = styled.div`
${flexCenter}
width: 100%;
height: 100px; 
`;


export const Logotipo = styled.img`
  width: 70%;
  margin-bottom: 0; 
`;

