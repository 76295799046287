import React from "react";
import {
	Document,
	Page,
	Text,
	View,
	StyleSheet,
	Image,
	Font,
} from "@react-pdf/renderer";
import { format, parse } from "date-fns";
import ItemsTable from "../../../PDFTable";

Font.register({
	family: "Ubuntu",
	fonts: [
		{
			src: "https://fonts.gstatic.com/s/ubuntu/v15/4iCv6KVjbNBYlgoC1CzTtw.ttf",
			fontWeight: 300,
		},
		{
			src: "https://fonts.gstatic.com/s/ubuntu/v15/4iCs6KVjbNBYlgo6eA.ttf",
			fontWeight: 400,
		},
		{
			src: "https://fonts.gstatic.com/s/ubuntu/v15/4iCv6KVjbNBYlgoCjC3Ttw.ttf",
			fontWeight: 500,
		},
		{
			src: "https://fonts.gstatic.com/s/ubuntu/v15/4iCv6KVjbNBYlgoCxCvTtw.ttf",
			fontWeight: 700,
		},
	],
});

// Create styles
const styles = StyleSheet.create({
	page: {
		backgroundColor: "white",
		fontFamily: "Ubuntu",
		margin: 10,
		padding: 10,
	},
	title: {
		width: "100%",
		textAlign: "center",
		fontSize: 15,
		marginBottom: 24,
		fontWeight: "bold",
	},
	headerText: {
		width: "100%",
		textAlign: "left",
		fontSize: 13,
		marginBottom: 8,
	},
	bold: {
		fontWeight: "bold",
	},
	containerTable: {
		width: "100%",
		display: "flex",
		flexDirection: "column",
	},
	containerChartImage: {
		width: "100%",
		display: "flex",
		alignItems: "center",
		flexDirection: "column",
		justifyContent: "center",
	},
	estimatedSeveriy: {
		width: "95%",
		maxHeight: "300px",
		objectFit: "contain",
	},
	containerChart: {
		width: "73%",
		height: "100%",
		display: "flex",
		alignItems: "center",
		flexDirection: "column",
	},
	chartConfigContainer: {
		width: "100%",
		display: "flex",
		alignItems: "center",
		flexDirection: "column",
	},
	tableContainer: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		width: "100%",
	},
	chartImage: {
		height: "100%",
		objectFit: "contain",
	},
	containerAnalysis: {
		width: "100%",
		display: "flex",
		flexDirection: "column",
		marginVertical: 20,
	},
	textConfig: {
		width: "100%",
		textAlign: "left",
		fontSize: 10,
		marginBottom: 4,
	},
	textAnalysis: {
		width: "100%",
		textAlign: "left",
		fontSize: 10,
		marginBottom: 12,
	},
	separator: {
		marginVertical: 12,
		width: "100%",
		height: 1,
		backgroundColor: "lightgrey",
	},
	pageNumber: {
		position: "absolute",
		fontSize: 12,
		bottom: 30,
		left: 0,
		right: 0,
		textAlign: "center",
		color: "grey",
	},
});

const capilizeCharAt0 = (str) => str[0]?.toUpperCase() + str.substring(1);

const formatDate = (ddddMMyy) =>
	format(parse(ddddMMyy, "yyyy-MM-dd", new Date()), "MM/dd/yyyy");

const classificationMap = {
	scottknott: "ScottKnott",
	duncan: "Duncan",
	tukey: "Tukey",
};

// Create Document Component
const PDFVigorYield = ({
	trial,
	assessment,
	analysis,
	chartImage,
	chartData,
	sortBy,
	classification,
}) => (
	<Document>
		<Page
			size="A4"
			style={styles.page}
		>
			<Text style={styles.title}>
				{trial?.name} - {capilizeCharAt0(assessment.type)} (
				{formatDate(assessment.date)})
			</Text>
			<Text style={styles.headerText}>
				Crop: <Text style={styles.bold}>{trial?.crop}</Text>
			</Text>
			<Text style={styles.headerText}>
				Season: <Text style={styles.bold}>{trial?.season}</Text>
			</Text>

			<View style={styles.separator} />

			<View style={styles.containerTable}>
				<View style={styles.chartConfigContainer}>
					<Text
						style={{
							...styles.headerText,
							fontWeight: "bold",
						}}
					>
						Estimated {capilizeCharAt0(assessment.type)}
					</Text>
					<Text style={styles.textConfig}>
						Sorted by:{" "}
						<Text style={styles.bold}>{capilizeCharAt0(sortBy)}</Text>
					</Text>
				</View>
				<View style={styles.tableContainer}>
					<ItemsTable
						widths={["19%", "19%", "19%", "19%", "19%"]}
						data={[
							["Treatment", "Estimated value", "ScottKnott", "Duncan", "Tukey"],
							...chartData
								?.sort((b, a) =>
									sortBy === "ranking"
										? Number(a.severity) - Number(b.severity)
										: Number(b.name.replace("T", "")) -
										  Number(a.name.replace("T", ""))
								)
								.map((s) => [
									s.name,
									`${Number(s.severity || "0")?.toFixed(2)}${
										assessment.type === "yield" ? " kg/ha" : "%"
									}`,
									s.scottknott,
									s.duncan,
									s.tukey,
								]),
						]}
					/>
				</View>
			</View>

			<View style={styles.containerAnalysis}>
				<Text
					style={{
						...styles.headerText,
						fontWeight: "bold",
					}}
				>
					Analysis
				</Text>
				<View style={{ flexDirection: "row" }}>
					<View style={{ width: "25%" }}>
						<Text style={styles.textAnalysis}>
							Alpha: <Text style={styles.bold}>{analysis.alpha}</Text>
						</Text>
						<Text style={styles.textAnalysis}>
							CV: <Text style={styles.bold}>{analysis.cv}</Text>
						</Text>
						<Text style={styles.textAnalysis}>
							DMSTukey: <Text style={styles.bold}>{analysis.dms}</Text>
						</Text>
						<Text style={styles.textAnalysis}>
							GL: <Text style={styles.bold}>{analysis.gl}</Text>
						</Text>
					</View>
					<View style={{ width: "75%" }}>
						<Text style={styles.textAnalysis}>
							Homogeneity:{" "}
							<Text style={styles.bold}>{analysis.homogeneidade}</Text>
						</Text>
						<Text style={styles.textAnalysis}>
							Normality: <Text style={styles.bold}>{analysis.normalidade}</Text>
						</Text>
						<Text style={styles.textAnalysis}>
							P Value: <Text style={styles.bold}>{analysis.p_valor}</Text>
						</Text>
						<Text style={styles.textAnalysis}>
							QMres: <Text style={styles.bold}>{analysis.qmres}</Text>
						</Text>
					</View>
				</View>
			</View>

			<View style={styles.containerChartImage}>
				<View style={styles.chartConfigContainer}>
					<Text style={styles.textConfig}>
						Sorted by:{" "}
						<Text style={styles.bold}>{capilizeCharAt0(sortBy)}</Text>
					</Text>
					{classification?.trim() ? (
						<Text style={styles.textConfig}>
							Classification:{" "}
							<Text style={styles.bold}>
								{classificationMap[classification] || ""}
							</Text>
						</Text>
					) : null}
				</View>
				<Image
					style={styles.estimatedSeveriy}
					src={chartImage}
				/>
			</View>

			<Text
				style={styles.pageNumber}
				render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
				fixed
			/>
		</Page>
	</Document>
);

export default PDFVigorYield;
