import React from "react";
import { AiOutlineMinusCircle, AiFillCheckCircle } from "react-icons/ai";
/**
 * Example:
 * images = {
 *  'RED': true,
 *  'BLUE': false
 *  ...
 * }
 */

const ImageChecklist = ({ images = {} }) => {
	return (
		<div>
			<div
				style={{
					fontWeight: "bold",
					width: "100%",
					textAlign: "center",
					marginBottom: "12px",
				}}
			>
				Image Checklist
			</div>
			<div
				style={{
					display: "flex",
					flexFlow: "column wrap",
					height: "75%",
					width: "100%",
				}}
			>
				{Object.entries(images).map(([imgName, exists]) => (
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
						key={imgName}
					>
						<span style={{ width: "24px", margin: "0 6px" }}>
							{exists ? (
								<AiFillCheckCircle
									color="#28a754"
									size={24}
								/>
							) : (
								<AiOutlineMinusCircle
									color="gray"
									size={24}
								/>
							)}
						</span>
						<span style={{ width: "200px" }}>{imgName}</span>
					</div>
				))}
			</div>
		</div>
	);
};

export default ImageChecklist;
