import React from "react";
import Chart from "react-apexcharts";

const DonutChart = ({ series, categories }) => {
	const options = {
		chart: {
			toolbar: {
				show: false,
			},
		},
		labels: categories,
	};

	return (
		<div
			className="w-100 d-flex"
			style={{ height: "100%" }}
		>
			<div className="w-100">
				<Chart
					options={options}
					series={series[0].data}
					type="donut"
					width={"100%"}
					height={"100%"}
				/>
			</div>
		</div>
	);
};

export default DonutChart;
