import styled from "styled-components";

export const Container = styled.div`
	width: 100%;

	align-items: center;
	text-align: center;
	display: flex;

	flex-direction: column;
 
`;

export const ControllerContainer = styled.div`
	width: 100%;
  display: flex; 
  padding: 24px; 
  z-index: 2;
 
	border-bottom: 1px solid #e0e0e0;
	max-height: 120px; 
`;

export const TableContainer = styled.div` 
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  overflow: auto; 
  justify-content: flex-start;
	padding: 0 1.5em;
	`;

export const CustomTable = styled.table`
	width: calc(50% - 48px);
	height: fit-content;
	border-collapse: separate;
	border-spacing: 0 12px ;

	thead {
		width: 100% !important;
	}
	tbody {
		width: 100% !important;
	}
	 
	thead tr {
		border: 1px solid orange;
		position: sticky;
		top: 0;
		outline: 10px solid white; 
		background-color: white;
	}

	thead tr th {
		max-height: 64px;
		padding: 12px 0; 
	}

	tbody tr {
		box-shadow: 0px 2px 10px rgb(0 0 0 / 20%);
		border-radius: 8px;
		cursor: pointer;
		height: 64px !important; 
	}

	tbody tr td {
		padding: 12px 0;
		height: 64px !important;  
		border-radius: 0;
	}

	tbody tr td:first-child {
		padding: 12px 0;
		height: 64px !important;  
		border-top-left-radius: 8px;
		border-bottom-left-radius: 8px;
	}

	tbody tr td:last-child {
		padding: 12px 0;
		height: 64px !important;   
		border-top-right-radius: 8px;
		border-bottom-right-radius: 8px;
	}

	tbody tr td:hover {
		background-color: #f8d9d9 ;
	}

	tbody tr:hover {
		background-color: #f8d9d9 ; 
	} 
`;