import React from "react";
import Chart from "react-apexcharts";

const LineChart = ({ series, categories, points }) => {
	const options = {
		annotations: {
			points,
		},
		chart: {
			type: "line",
			toolbar: {
				show: false,
			},
			zoom: {
				enabled: false,
			},
		},
		plotOptions: {},
		stroke: {
			width: 4,
			curve: "smooth",
		},
		grid: {
			row: {
				colors: ["#fff", "#f2f2f2"],
			},
		},
		xaxis: {
			categories,
			tickPlacement: "on",
		},
		dataLabels: {
			enabled: true,
			formatter: function (val) {
				return val?.toFixed(2);
			},
			offsetY: 0,
			style: {
				fontSize: "10px",
			},
		},
		yaxis: {
			labels: {
				formatter: (v) => v.toFixed(2),
			},
		},
	};

	return (
		<div
			className="w-100 d-flex"
			style={{ height: "45vh" }}
		>
			<div className="w-100">
				<Chart
					options={options}
					series={series}
					type="line"
					width={"100%"}
					height={"100%"}
				/>
			</div>
		</div>
	);
};

export default LineChart;
