import React from "react";
import { Container } from "react-modal-promise";

import MainView from "./components/MainView";
import Sidebar from "./components/Sidebar";



function App() {

  return (
    <div className="main-layout">
      <Sidebar />
      <MainView />
      <Container />
    </div>
  );
}

export default App;
