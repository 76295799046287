import Button from "react-bootstrap/Button";
import { useLocation } from "react-router-dom";

const Error404 = () => {
	const location = useLocation();
	return (
		<div
			style={{
				width: "100vw",
				height: "100vh",
				background: "#d0d8c6",
			}}
			className="d-flex align-items-center justify-content-center flex-column"
		>
			<p>
				The page <strong>{window.location.pathname}</strong> was not found!{" "}
				<br />
			</p>
			<Button
				variant="success"
				onClick={() => location.to("/")}
			>
				Go to home
			</Button>
		</div>
	);
};

export default Error404;
