import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import Trials from "../pages/Trials";
import TrialAssessment from "../pages/TrialAssessment";
import FieldMap from "../pages/FieldMap";
import Users from "../pages/Users";
import FieldItems from "../pages/FieldItems";
import Login from "../pages/Login";
import AuthRoute from "./components/AuthRoute";
import Error404 from "../pages/404";
import Home from "../pages/Home";

export default function Routes() {
	return (
		<Switch>
			<Route
				exact
				path="/login"
				component={Login}
			/>
			<AuthRoute
				exact
				path="/"
			>
				<Redirect to="/home" />
			</AuthRoute>
			<AuthRoute
				exact
				path="/home"
				component={Home}
			/>
			<AuthRoute
				exact
				path="/trial"
				component={Trials}
			/>
			<AuthRoute
				exact
				path="/trial/assessment"
				component={TrialAssessment}
			/>
			<AuthRoute
				exact
				path="/users"
				component={Users}
			/>

			<AuthRoute
				exact
				path="/field-map"
				component={FieldMap}
			/>
			<AuthRoute
				exact
				path="/field-items"
				component={FieldItems}
			/>
			<AuthRoute
				exact
				path="/training-model"
				component={() => <Trials training />}
			/>
			<AuthRoute
				exact
				path="/training-model/assessment"
				component={TrialAssessment}
			/>

			<Route
				path="*"
				element={<Error404 />}
			/>
		</Switch>
	);
}
