import React from "react";
import { Route, Redirect } from "react-router-dom";

const AuthRoute = (props) => {
	const token = localStorage.getItem("token");

	if (!token) {
		return (
			<Redirect
				to={{
					pathname: "/login",
				}}
			/>
		);
	}

	return <Route {...props} />;
};

export default AuthRoute;
