import { useState } from "react";
import { create } from "react-modal-promise";
import { format, parse } from "date-fns";
import { translateAsessmentType } from "../../pages/TrialAssessment";
import { Button, Col, Form, Modal } from "react-bootstrap";

const ModalCopyConfiguration = ({ isOpen, onResolve, assessmentList }) => {
	const [selectedAssessment, setSelectedAssessment] = useState(-1);

	const submit = () => {
		onResolve({
			assessment_id: selectedAssessment,
		});
	};

	if (!isOpen) return null;

	return (
		<Modal
			centered
			show={isOpen}
			onHide={() => onResolve()}
		>
			<Modal.Header closeButton>
				<Modal.Title>Copying assessment configuration</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<p>Select the referenced assessment</p>
				<Col>
					{[...assessmentList].map((as) => (
						<Form.Check
							type="radio"
							id={`checkbox-${as.id}`}
							key={`checkbox-${as.id}`}
							label={`${translateAsessmentType[as.type]} - ${format(
								parse(as.date, "yyyy-MM-dd", new Date()),
								"dd/MM/yyyy"
							)}`}
							checked={selectedAssessment === as.id}
							onChange={() => setSelectedAssessment(as.id)}
						/>
					))}
				</Col>
			</Modal.Body>
			<Modal.Footer>
				<Button
					variant="secondary"
					onClick={() => onResolve()}
				>
					Cancel
				</Button>
				<Button
					variant="success"
					disabled={selectedAssessment < 1}
					onClick={submit}
				>
					Confirm
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default create(ModalCopyConfiguration);
