export const assessmentTypeList = [
  {
    value: "severity",
    name: "Severity",
  },
  {
    value: "target_spot",
    name: "Target spot",
  },
  {
    value: "yield",
    name: "Yield",
  },
  {
    value: "vigor",
    name: "Vigor",
  },
];
