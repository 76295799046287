import React from "react";
import Chart from "react-apexcharts";

const BarChart = ({
	series,
	categories,
	points = [],
	options: customOptions,
}) => {
	const options = {
		annotations: {
			points: points.map((p) => ({
				...p,
				x: p.y,
				y: p.x,
				label: { ...p.label, offsetY: 6 },
			})),
		},
		chart: {
			type: "bar",
			toolbar: {
				show: false,
			},
			zoom: {
				enabled: false,
			},
		},
		plotOptions: {
			bar: {
				horizontal: true,
				borderRadius: 4,
				distributed: true,
				dataLabels: {
					position: "top",
				},
			},
		},
		stroke: {
			width: 2,
		},
		legend: {
			show: false,
		},
		xaxis: {
			categories,
			tickPlacement: "on",
			tickAmount: 3,
		},
		yaxis: {
			max: Math.max(...series[0].data) * 1.25,
		},
		dataLabels: {
			enabled: true,
			offsetX: 36,
			style: {
				fontSize: "14px",
				colors: ["#304758"],
			},
		},
		...customOptions,
	};

	return (
		<div
			className="w-100 d-flex"
			style={{ height: "100%" }}
		>
			<div className="w-100">
				<Chart
					options={options}
					series={series}
					type="bar"
					width={"100%"}
					height={"100%"}
				/>
			</div>
		</div>
	);
};

export default BarChart;
