/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo } from "react";
import { differenceInDays, format, parse, parseISO } from "date-fns";
import { Button, Col, Form, Modal, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";

import Api from "../../services/api";

import ApplicationMap from "../../components/ApplicationMap";
import DataVisualization from "../../components/DataVisualization";
import Assessment from "../../components/Assessment";
import Audpc from "../../components/Audpc";

import icone_assesstment from "../../assets/plant-icon.svg";
import icone_datavisu from "../../assets/drone-icon.svg";
import icone_app from "../../assets/graph_icon.svg";
import icone_map from "../../assets/street_map_icon.svg";

import { FaPencilAlt, FaTrash } from "react-icons/fa";
import { assessmentTypeList } from "./helper";
import AssessmentTraining from "../../components/AssessmentTraining";

import ConfirmacaoAsync from "../../components/ConfirmacaoAsync";

import * as S from "./styles";
import Dropzone from "react-dropzone";
import ImageJS from "image-js";

export const translateAsessmentType = {
	severity: "Severity",
	target_spot: "Target spot",
	yield: "Yield",
	vigor: "Vigor",
};

export default function TrialAssessment(props) {
	const [showModal, setShowModal] = useState(false);
	const [showModalEdit, setShowModalEdit] = useState(false);
	const [selected, setSelected] = useState("");
	const [loading, setLoading] = useState(true);

	const [assessments, setAssessments] = useState([]);

	const [newAssessmentImageFile, setNewAssessmentImageFile] = useState({
		file: undefined,
		src: "",
	});
	const [loadingNewImage, setLoadingNewImage] = useState(false);

	const [altitude, setNewAltitude] = useState("");
	const [time, setTime] = useState(undefined);
	const [newdate, setNewDate] = useState("");
	const [newType, setNewType] = useState("severity");
	const [selectedDate, setSelectedDate] = useState(0);

	const [content, setContent] = useState({});

	const [tableContentAppMap, setTableContentAppMap] = useState([]);
	const [tableContentDataVisu, setTableContentDataVisu] = useState([]);

	const [assessment_id, setAssessment_id] = useState();
	const [savingAssessment, setSavingAssessment] = useState(false);

	const training = useMemo(
		() => !!props.location.state?.training,
		[props.location.state?.training]
	);

	const treatments_count = useMemo(
		() => props.location.state.treatments_count,
		[props?.location?.state?.treatments_count]
	);

	const repetitions_count = useMemo(
		() => props.location.state.repetitions_count,
		[props?.location?.state?.repetitions_count]
	);

	const trial_id = useMemo(
		() => props.location.state?.id,
		[props.location.state?.id]
	);

	const trialInfo = useMemo(() => {
		const {
			id = "",
			name = "",
			season = "",
			crop = "",
			sowing_date = "",
		} = props.location.state;

		return {
			id,
			name,
			season,
			crop,
			sowing_date,
		};
	}, [props.location.state]);

	const sowing_date = useMemo(
		() => props.location.state.sowing_date,
		[props.location.state.sowing_date]
	);

	const differenceAssessmentDateInDays = useMemo(() => {
		try {
			const date = parse(newdate || "", "yyyy-MM-dd", new Date());
			return differenceInDays(date, parseISO(sowing_date));
		} catch (error) {
			return 0;
		}
	}, [newdate, sowing_date]);

	var selectedStyle = {
		color: "#EE2E30",
	};

	var selectedDateStyle = {
		background: "#EE2E30",
		color: "#FFFFFF",
	};

	const handleDelete = async (item) => {
		const confirmation = await ConfirmacaoAsync({
			title: "Delete this assessment?",
			message: "Are you sure want to delete this assessment?",
			variant: "danger",
		});

		if (confirmation) {
			try {
				await Api.delete(`/assessment/${item?.id}`);

				setAssessments((s) => s.filter((a) => a?.id !== item?.id));
			} catch (err) {
				console.log(err);
				toast.success("Error deleting assessment!");
			}
		}
	};

	const handleImageDrop = async (files) => {
		try {
			if (!files.length) return;

			const file = files[0];

			const reader = new FileReader();
			setLoadingNewImage(true);

			const src = await new Promise((res, rej) => {
				reader.onload = async (e) => {
					if (file.type === "image/tiff") {
						const arrayBuffer = e.target.result;
						const image = await ImageJS.load(arrayBuffer);
						const base64Data = image.toDataURL("image/png"); // Convert to PNG for compatibility

						res(base64Data);
					} else {
						res(e.target.result);
					}
				};
				reader.onerror = (e) => rej(e);
				reader.readAsDataURL(file);
			});

			setNewAssessmentImageFile({ file, src });
		} catch (error) {
			toast.error("There was an error handling the image.");
		}
		setLoadingNewImage(false);
	};

	const tablegenerator = () => {
		var result = [];
		let t_name = 0,
			aux = 0,
			x = 100;

		for (let index = 0; index < treatments_count * repetitions_count; index++) {
			if (aux < treatments_count) {
				aux = aux + 1;
			} else {
				aux = 1;
				x = x + 100;
			}
			t_name = x + aux;

			result.push({
				treatment_name: t_name.toString(),
				treatment_valor: "",
			});
		}
		return result;
	};

	const handleEdit = () => {
		setSavingAssessment(true);
		const novosDados = {
			trial_id: trial_id,
			date: newdate,
			type: newType,
			...(training
				? {
						time,
						altitude,
				  }
				: {}),
		};
		Api.put(`/assessment/${assessment_id}`, novosDados)
			.then((res) => {
				setAssessments((s) =>
					s.map((av) => (av.id === assessment_id ? res.data : av))
				);
				toast.success("Assessment saved successfully!");

				setNewAltitude("30m");
				setTime(undefined);
			})
			.catch((err) => {
				console.log(err);
				toast.success("Error!");
			})
			.finally(() => {
				setSavingAssessment(false);
				setNewDate("");
				setShowModalEdit(false);
			});
	};

	const handleSave = () => {
		setSavingAssessment(true);
		Api.post("/assessment", {
			trial_id: trial_id,
			date: newdate,
			type: newType,
			...(training
				? {
						time,
						altitude,
				  }
				: {}),
		})
			.then(async (res) => {
				var formData = new FormData();

				formData.append("imgsAssessment", newAssessmentImageFile.file);

				await Api.post(
					`/assessment/upload_${props.location.state?.image_type}_img/${res.data.id}`,
					formData,
					{
						headers: {
							"content-type": "multipart/form-data",
						},
					}
				);

				setAssessments((s) => [...s, res.data]);
				setSelectedDate(assessments.length);

				toast.success("Assessment saved successfully!");

				setNewAltitude("30m");
				setTime(undefined);
			})
			.catch((err) => {
				console.log(err);
				toast.success("Error!");
			})
			.finally(() => {
				setSavingAssessment(false);
				setNewAssessmentImageFile({ src: "", file: undefined });
				setNewDate("");
				setShowModal(false);
			});
	};

	const renderAssessment = useMemo(() => {
		if (training)
			return (
				<AssessmentTraining
					trial_id={trial_id}
					image_type={props.location.state?.image_type}
					content={content}
					assessment_id={assessment_id}
					assessments={assessments}
					setOriginalAssessments={setAssessments}
					selected_date={selectedDate}
					training={training}
					n_treatments={treatments_count}
					n_repetitions={repetitions_count}
					tableContentAppMap={tableContentAppMap}
					goToAssessment={() => setSelected("Data Visualization")}
				/>
			);

		return (
			<Assessment
				trial_id={trial_id}
				image_type={props.location.state?.image_type}
				content={content}
				assessment_id={assessment_id}
				assessments={assessments}
				setOriginalAssessments={setAssessments}
				selected_date={selectedDate}
				training={training}
				n_treatments={treatments_count}
				n_repetitions={repetitions_count}
				tableContentAppMap={tableContentAppMap}
				goToAssessment={() => setSelected("Data Visualization")}
			/>
		);
	}, [content, assessment_id, assessments, selectedDate, training]);

	useEffect(() => {
		const fetch = async () => {
			var table = tablegenerator();

			setLoading(true);
			await Api.get(`/assessment/${trial_id}`)
				.then((res) => {
					setAssessments(res.data);
				})
				.catch((err) => {
					console.log("err> ", err);
					toast.success("Error!");
				});

			setLoading(false);
			setContent(props.location.state);
			setTableContentDataVisu(table);
			setTableContentAppMap(table);
			setSelected("Application Map");

			const target = props.location.state.target?.trim();
			if (!!target) setNewType(target);
		};

		fetch();
	}, [trial_id]);

	useEffect(() => {
		if (assessments.length > 0) {
			setAssessment_id(assessments[selectedDate]?.id);
			setSelected("Assessment");
		}
	}, [assessments]);

	useEffect(() => {
		if (assessments.length > 0) {
			setContent(props.location.state);
			setAssessment_id(assessments[selectedDate]?.id);
		}
	}, [selectedDate]);

	const hideMenu = ["Application Map", "audpc"]?.includes(selected);

	return (
		<>
			{loading ? (
				<Col className="w-100 h-100 d-flex align-items-center justify-content-center">
					<Spinner variant="success" />
				</Col>
			) : (
				<S.Container
					hideMenu={hideMenu}
					style={training ? { width: "unset" } : {}}
				>
					<S.AsSidebar hideMenu={hideMenu}>
						<S.AsSidebarHeader>
							<Button
								className="w-100"
								variant="success"
								onClick={() => {
									setShowModal(true);
								}}
							>
								{"New Assessment +"}
							</Button>
						</S.AsSidebarHeader>

						<S.AsSidebarBody>
							<span>Assessments List</span>
							{assessments.map((item, index) => {
								return (
									<S.AsSidebarCard
										onClick={() => {
											setSelectedDate(index);
										}}
										key={index}
										style={
											index === selectedDate
												? { ...selectedDateStyle, position: "relative" }
												: { position: "relative" }
										}
									>
										<span>{translateAsessmentType[item.type]}</span>
										<span>
											{format(
												parse(item.date, "yyyy-MM-dd", new Date()),
												training ? "dd/MM/yyyy" : "MM/dd/yyyy"
											)}
										</span>

										{training ? (
											<div
												style={{
													position: "absolute",
													right: "32px",
												}}
												onClick={(e) => {
													e.stopPropagation();
													setAssessment_id(item.id);
													setSelectedDate(index);
													setShowModalEdit(true);
													setNewType(item.type);
													setNewDate(item.date);
													setTime(item.time);
													setNewAltitude(item.altitude);
												}}
											>
												<FaPencilAlt
													color={index === selectedDate ? "white" : "grey"}
												/>
											</div>
										) : null}
										<div
											style={{
												position: "absolute",
												right: "12px",
											}}
											onClick={(e) => {
												e.stopPropagation();
												handleDelete(item);
											}}
										>
											<FaTrash
												color={index === selectedDate ? "white" : "grey"}
											/>
										</div>
									</S.AsSidebarCard>
								);
							})}
						</S.AsSidebarBody>
					</S.AsSidebar>
					<S.AsContentContainer>
						<S.AsContentHeader>
							<Col
								md={training ? 6 : 3}
								style={selected === "Application Map" ? selectedStyle : {}}
								onClick={() => {
									setSelected("Application Map");
								}}
							>
								<img
									style={{ marginRight: "10px", padding: "4px" }}
									src={icone_map}
									alt="icon"
								/>
								Application Map
							</Col>
							<Col
								md={training ? 6 : 3}
								style={selected === "Assessment" ? selectedStyle : {}}
								onClick={() => {
									setSelected("Assessment");
								}}
							>
								<img
									style={{ marginRight: "10px" }}
									src={icone_assesstment}
									alt="icon"
								/>
								Assessment
							</Col>
							{training ? null : (
								<>
									<Col
										md={3}
										style={
											selected === "Data Visualization" ? selectedStyle : {}
										}
										onClick={() => {
											setSelected("Data Visualization");
										}}
									>
										<img
											style={{ marginRight: "10px", padding: "2px" }}
											src={icone_datavisu}
											alt="icon"
										/>
										Data Visualization
									</Col>
									<Col
										md={3}
										style={selected === "audpc" ? selectedStyle : {}}
										onClick={() => {
											setSelected("audpc");
										}}
									>
										<img
											style={{ marginRight: "10px", padding: "2px" }}
											src={icone_app}
											alt="icon"
										/>
										AUDPC
									</Col>
								</>
							)}
						</S.AsContentHeader>

						{(() => {
							if (selected === "Assessment") {
								return renderAssessment;
							} else if (selected === "Data Visualization") {
								return (
									<DataVisualization
										trial={trialInfo}
										treatments_count={treatments_count}
										repetitions_count={repetitions_count}
										assessment_id={assessment_id}
										tableContent={tableContentDataVisu}
										type={assessments[selectedDate].type}
										assessment={assessments[selectedDate]}
									/>
								);
							} else if (selected === "Application Map") {
								return (
									<ApplicationMap
										treatments_count={treatments_count}
										repetitions_count={repetitions_count}
										trial_id={trial_id}
										tableContent={tableContentAppMap}
										goToAssessment={() => setSelected("Assessment")}
									/>
								);
							} else if (selected === "audpc") {
								return <Audpc trial={trialInfo} />;
							}
						})()}
					</S.AsContentContainer>
				</S.Container>
			)}

			{/* Modal new assessment */}
			{showModal ? (
				<Modal
					centered
					show
					onHide={() => setShowModal(false)}
				>
					<Modal.Header closeButton>
						<Modal.Title>New Assessment</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Form.Group
							as={Col}
							className="mb-3"
						>
							<Form.Label>Type</Form.Label>
							<Form.Select
								disabled={training}
								value={newType}
								onChange={(e) => setNewType(e.target.value)}
							>
								{assessmentTypeList
									.filter(
										(type) =>
											!(
												type.value === "target_spot" &&
												props.location.state?.image_type === "multispectral"
											)
									)
									.map((type) => (
										<option
											key={type.value}
											value={type.value}
										>
											{type.name}
										</option>
									))}
							</Form.Select>
						</Form.Group>
						<Form.Group
							as={Col}
							className="mb-3"
						>
							<Form.Label>Date</Form.Label>
							<Form.Control
								type="date"
								value={newdate}
								onChange={(newdate) => {
									setNewDate(newdate.target.value);
								}}
							/>
						</Form.Group>

						<Form.Group
							as={Col}
							className="mb-3"
						>
							<Form.Label>Image</Form.Label>
							{!newAssessmentImageFile.file && !loadingNewImage ? (
								<Dropzone
									maxFiles={1}
									accept={{
										"image/*": [".png", ".jpeg", ".tiff"],
									}}
									onDrop={(acceptedFiles) => console.log(acceptedFiles)}
									onDropAccepted={handleImageDrop}
									onDropRejected={() =>
										toast.warning(
											"Make sure to select only one image (PNG, JPEG or TIFF)"
										)
									}
									multiple={false}
								>
									{({
										getRootProps,
										getInputProps,
										isFocused,
										isDragAccept,
										isDragReject,
									}) => (
										<S.DzSection
											isFocused={isFocused}
											isDragAccept={isDragAccept}
											isDragReject={isDragReject}
										>
											<div {...getRootProps()}>
												<input {...getInputProps()} />
												<span>Drop or click to select an image </span>
											</div>
										</S.DzSection>
									)}
								</Dropzone>
							) : null}

							{newAssessmentImageFile.src?.trim() || loadingNewImage ? (
								<S.ImgPreviewContainer>
									{loadingNewImage ? (
										<Col className="w-100 h-100 d-flex align-items-center justify-content-center">
											<Spinner
												variant="secondary"
												animation="border"
											/>
										</Col>
									) : (
										<>
											<S.ImgPreview src={newAssessmentImageFile.src} />
											<S.ImgPreviewDelete
												onClick={() =>
													setNewAssessmentImageFile({
														file: undefined,
														src: "",
													})
												}
											>
												<FaTrash
													size={24}
													color="white"
												/>
											</S.ImgPreviewDelete>
										</>
									)}
								</S.ImgPreviewContainer>
							) : null}
						</Form.Group>

						{!training && differenceAssessmentDateInDays <= 70 ? (
							<S.AsAlert>
								Dates of selected images must be greater than 70 days after
								sowing. Otherwise, the algorithm will not be able to predict the
								expected results with sufficient accuracy.
							</S.AsAlert>
						) : null}
					</Modal.Body>
					<Modal.Footer>
						<Button
							variant="success"
							disabled={!newdate?.toString().trim() || savingAssessment}
							onClick={handleSave}
						>
							{savingAssessment ? (
								<Col className="d-flex justify-content-center align-items-center gap-1">
									Saving...{" "}
									<Spinner
										size="sm"
										animation="border"
										variant="light"
									/>
								</Col>
							) : (
								"Save"
							)}
						</Button>
					</Modal.Footer>
				</Modal>
			) : null}

			{/* Modal edit assessment */}
			{training && showModalEdit ? (
				<Modal
					centered
					show
					onHide={() => setShowModalEdit(false)}
				>
					<Modal.Header closeButton>
						<Modal.Title>Edit Assessment</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Form.Group
							as={Col}
							className="mb-3"
						>
							<Form.Label>Type</Form.Label>
							<Form.Select
								disabled={training}
								value={newType}
								onChange={(e) => setNewType(e.target.value)}
							>
								{assessmentTypeList
									.filter(
										(type) =>
											!(
												type.value === "target_spot" &&
												props.location.state?.image_type === "multispectral"
											)
									)
									.map((type) => (
										<option
											key={type.value}
											value={type.value}
										>
											{type.name}
										</option>
									))}
							</Form.Select>
						</Form.Group>
						<Form.Group
							as={Col}
							className="mb-3"
						>
							<Form.Label>Date</Form.Label>
							<Form.Control
								type="date"
								value={newdate}
								onChange={(newdate) => {
									setNewDate(newdate.target.value);
								}}
							/>
						</Form.Group>
					</Modal.Body>{" "}
					<Modal.Footer>
						<Button
							variant="success"
							disabled={
								!newdate?.toString().trim() ||
								savingAssessment ||
								!newAssessmentImageFile
							}
							onClick={handleEdit}
						>
							{savingAssessment ? (
								<Col className="d-flex justify-content-center align-items-center gap-1">
									Saving...{" "}
									<Spinner
										size="sm"
										animation="border"
										variant="light"
									/>
								</Col>
							) : (
								"Save"
							)}
						</Button>
					</Modal.Footer>
				</Modal>
			) : null}
		</>
	);
}
