import styled from "styled-components";
import { flexCenter } from "../Login/styles";

export const Container = styled.div`
	width: 100%;
	height: 100%;
	display: grid;
	grid-template-columns: ${({ hideMenu }) => hideMenu ? '0 100%' : "250px calc(100% - 250px)"} ;
	grid-template-rows: 100%;
	transition: all .2s linear;
`;

export const AsSidebar = styled.div`
	width:0 ;
	opacity:0;
	height: 100%;
	background-color: rgb(241, 241, 241);
	display: flex;
	flex-direction: column;

	/* transition: width .3s linear, opacity .5s linear; */
	overflow-x: hidden;

	animation: ${({ hideMenu }) => hideMenu ? 'sidebar-hide' : 'sidebar-reveal'} .3s linear forwards;


	@keyframes sidebar-hide {
		0% {
			opacity: 1;
			width: 250px;
		}
		70% {
			opacity: 0;
			width: 170px;
		}
		100% {
			opacity: 0;
			width: 0;
		}
	}

	@keyframes sidebar-reveal {
		0% {
			opacity: 0;
			width: 0;
		}
		30% {
			opacity: 0;
			width: 170px;
		}
		100% {
			opacity: 1;
			width: 250px;
		}
	}
`;

export const AsSidebarHeader = styled.div`
	${flexCenter}
	height: 80px;
	border-bottom: 1px solid #e1e1e1;
	padding: 1em; 
`;

export const AsSidebarBody = styled.div`
	display: flex;
	flex-direction: column;
	overflow: auto; 
	align-items: center;

	flex: 1;
	padding: 0.5em 0;
`;

export const AsSidebarCard = styled.div`
	display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  vertical-align: middle;

  width: calc(100% - 60px);
  height: fit-content!important;
  margin: 16px;
  border-radius: 10px;

  background: linear-gradient(180deg, #ffffff 0%, #ffffff 100%);
  box-shadow: 0px 2px 10px rgb(0 0 0 / 50%);
  
  padding: 10px;
 
  font-weight: bold;
  font-size: 14px; 
  color: #767676;  


  cursor: pointer;
 


`;

export const AsAlert = styled.div`
	background-color: rgb(253, 227, 227);
  padding: 16px;
  margin: 0 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  color: #a82222;
  border: 1px solid #ae8e8e;
`;

export const AsContentContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows:  80px 1fr;
	width: 100%;
	transition: all 1s ease-in;
`;

export const AsContentHeader = styled.div`
	
	border-bottom: 1px solid #e1e1e1;

	display: flex;

	& > div {
		${flexCenter}
		cursor: pointer;

		img {
			width: 32px;
			height: 32px;
		}
	}
`;


export const DzSection = styled.section` 
	div { 
		cursor: pointer;
		flex: 1;
		display: flex;
		height: 140px;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding: 20px;
		border-width: 2;
		border-radius: 8px;
		border-color: #eeeeee;
		border-style: dashed;
		background-color: #fafafa;
		color: #bdbdbd;
		outline: none;
		transition: all .24s ease-in-out;

		${({ isFocused }) => isFocused && `
			border-color: #2196f3;
			color: white;
			background-color: #2196f370;
		`}

		${({ isDragAccept }) => isDragAccept && `
			border-color: #00e676;
			color: white;
			background-color: #00e67670;
		`}

		${({ isDragReject }) => isDragReject && `
			border-color: #ff1744;
			color: white;
			background-color: #ff174470;
		`}
	}
`;

export const ImgPreviewDelete = styled.div`
	${flexCenter}
	width: 140px;
	height: 140px;
	opacity: 0;
	background-color: #ff00005c;
	transition: all 0.24s ease-in-out;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 100;
`;

export const ImgPreview = styled.img`
	width: 140px;
	height: 140px;
	object-fit: cover;
	
`;


export const ImgPreviewContainer = styled.div`
	width: 140px;
	height: 140px;
	border-radius: 12px;
	overflow: hidden;
	position: relative;
	border: 1px solid lightgray;
	background-color: #f1f1f1;

	cursor: pointer;
	&:hover {
		${ImgPreviewDelete} {
			opacity: 1;
		}
	}
`;

