import { format, parse } from "date-fns";
import React, { useEffect, useMemo, useState } from "react";
import { DataSheetGrid, keyColumn, textColumn } from "react-datasheet-grid";
import { IoMdClose } from "react-icons/io";
import { toast } from "react-toastify";

import { translateAsessmentType } from "../../../../pages/TrialAssessment";
import api from "../../../../services/api";
import { range } from "../../../../util/arrayHandler";

import "./styles.css";
import { Button, Modal, Spinner } from "react-bootstrap";

const ModalTrainingSheet = ({
	assessments,
	show,
	close,
	trial_id,
	n_repetitions,
	n_treatments,
	proceedSavingSamples,
}) => {
	const [loadingAssessments, setLoadingAssessments] = useState(true);
	const [savingData, setSavingData] = useState(false);
	const [modifiedRows, setModifiedRows] = useState([]);
	const [currentMeans, setCurrentMeans] = useState([]);
	const [data, setData] = useState([]);

	const treatmentCounterList = useMemo(
		() =>
			Array.from(
				{
					length: n_treatments,
				},
				(_, idx) => Array.from({ length: n_repetitions }, () => idx + 1)
			).flat(),
		[n_repetitions, n_treatments]
	);

	const handleSave = async () => {
		setSavingData(true);
		try {
			// Make shure to update only modified values
			const meansToUpdate = currentMeans.reduce((result, mean) => {
				const newValue = data[mean.am_row - 1][mean.am_assessment];
				if (
					// Modified row
					modifiedRows.includes(mean.am_row - 1) &&
					// Different value
					mean.am_value !== newValue
				)
					result.push({ ...mean, am_value: newValue });
				return result;
			}, []);

			await api.put(`/assessment/means/${trial_id}`, {
				means: meansToUpdate,
			});

			await proceedSavingSamples();

			close();
		} catch (error) {
			toast.error("There was an error while saving the assessment values.");
		}
		setSavingData(false);
	};

	const handleChange = (newData, config) => {
		const edit = config[0];

		try {
			const cheatsheet = range(edit.fromRowIndex, edit.toRowIndex - 1);

			setData(
				newData.map((row, idx) => {
					if (cheatsheet.includes(idx)) {
						row = Object.fromEntries(
							Object.entries(row).map(([key, item], idxRow) => {
								setModifiedRows((s) => [...new Set([...s, idx])]);
								const n = Number(item?.toString().replace(",", "."));
								item = Number.isNaN(n) ? 0 : n;
								return [key, item?.toFixed(2)];
							})
						);
					}
					return row;
				})
			);
		} catch (error) {}
	};

	const handleScroll = () => {
		const activeCells = document.getElementsByClassName("dsg-active-cell");

		if (activeCells?.length > 0) {
			const currentActiveCell = activeCells[0];

			if (currentActiveCell) currentActiveCell.scrollIntoView();
		}
	};

	useEffect(() => {
		const fetch = async () => {
			setLoadingAssessments(true);
			try {
				let { data: means } = await api.get(`/assessment/means/${trial_id}`, {
					params: {
						ids: assessments.map((as) => as.id),
					},
				});

				means = means.sort(
					(a, b) => Number(a.am_treatment) - Number(b.am_treatment)
				);
				means = means.sort(
					(a, b) => Number(a.am_assessment) - Number(b.am_assessment)
				);

				setCurrentMeans(means);
				const findValue = (am_assessment, am_row) =>
					means.find(
						(m) => m.am_assessment === am_assessment && m.am_row === am_row
					)?.am_value || 0;

				setData(
					treatmentCounterList.map((t, idx) => ({
						active: true,
						...Object.fromEntries(
							assessments.map((as) => [as.id, findValue(as.id, idx + 1)])
						),
					}))
				);
			} catch (error) {
				toast.error("There was an error while loading the assessment means.");
			}
			setLoadingAssessments(false);
		};
		fetch();
	}, [assessments, treatmentCounterList, trial_id]);

	const clearColumn = (id) => {
		setData((s) =>
			s.map((row, rowIdx) =>
				Object.fromEntries(
					Object.entries(row).map(([key, item]) => {
						if (key === id?.toString()) {
							item = 0;
							setModifiedRows((s) => [...new Set([...s, rowIdx])]);
						}
						return [key, item];
					})
				)
			)
		);
	};

	const columns = assessments.map((as) => ({
		...keyColumn(as.id, textColumn),
		title: (
			<span
				className="title-column"
				style={{
					width: "100%",
					display: "flex",
					alignItems: "center",
					justifyContent: "space-between",
					position: "relative",
					whiteSpace: "nowrap",
					overflow: "hidden",
				}}
			>
				<span>
					{translateAsessmentType[as.type]}{" "}
					{format(parse(as.date, "yyyy-MM-dd", new Date()), "dd/MM/yyyy")}
				</span>
				<IoMdClose
					style={{
						cursor: "pointer",
						margin: "0 6px",
					}}
					onClick={() => clearColumn(as.id)}
					color="red"
				/>{" "}
			</span>
		),
		minWidth: 250,
		maxWidth: 251,
	}));

	return (
		<Modal
			centered
			size="xl"
			show={show}
			onHide={close}
		>
			<Modal.Header closeButton>
				<Modal.Title>Training Sheet</Modal.Title>
			</Modal.Header>
			<Modal.Body style={{ overflow: "auto" }}>
				{loadingAssessments ? (
					<div className="datasheet-container d-flex justify-content-center align-items-center">
						<Spinner
							variant="success"
							animation="border"
						/>
					</div>
				) : (
					<>
						<div
							className="datasheet-container"
							id="datasheet-container"
						>
							<div
								style={{
									maxHeight: "60vh",
									width: `${40 + 250 * (assessments.length || 10)}px`,
								}}
							>
								<DataSheetGrid
									value={data}
									onChange={handleChange}
									columns={columns}
									autoAddRow={false}
									addRowsComponent={undefined}
									gutterColumn={{
										component: (e) => {
											return (
												<div
													className="d-flex flex-column justify-content-center align-items-center"
													style={{ lineHeight: "16px" }}
												>
													T{treatmentCounterList[e.rowIndex]} <br />
													<span style={{ fontSize: "0.8em" }}>
														({currentMeans[e.rowIndex]?.trialMap?.name})
													</span>
												</div>
											);
										},
										maxWidth: 300,
										minWidth: 300,
									}}
									onActiveCellChange={handleScroll}
									lockRows
								/>
							</div>
						</div>
					</>
				)}
			</Modal.Body>
			<Modal.Footer>
				<Button
					disabled={savingData}
					variant="secondary"
					onClick={close}
				>
					{"Cancel"}
				</Button>{" "}
				<Button
					disabled={savingData}
					variant="success"
					onClick={handleSave}
				>
					{savingData ? "Saving data..." : "Save and continue"}
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default ModalTrainingSheet;
