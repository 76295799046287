import { useMemo, useState } from "react";
import { Button, Col, Form, Modal } from "react-bootstrap";
import { create } from "react-modal-promise";

const ModalDownloadDataset = ({ isOpen, onResolve, trialList }) => {
	const [search, setSearch] = useState("");

	const [includePolygonName, setIncludePolygonName] = useState(false);
	const [includeAnalysis, setIncludeAnalysis] = useState(false);

	const [selectedTrials, setSelectedTrials] = useState({});

	const filteredTrialList = useMemo(
		() =>
			trialList.filter(
				(t) => t.name.toUpperCase().indexOf(search.toUpperCase()) !== -1
			),
		[trialList, search]
	);

	const allVisibleSelected = useMemo(() => {
		return filteredTrialList.every((t) => selectedTrials[t.id?.toString()]);
	}, [filteredTrialList, selectedTrials]);

	const selectAllVisible = (state) => {
		const changes = Object.fromEntries(
			filteredTrialList.map((t) => [t.id?.toString(), state])
		);

		setSelectedTrials((s) => ({ ...s, ...changes }));
	};

	const idsFiltrados = useMemo(() => {
		return Object.entries(selectedTrials)
			.filter(([, valor]) => valor)
			.map(([chave]) => chave);
	}, [selectedTrials]);

	const submit = () => {
		onResolve({
			trialIds: idsFiltrados,
			includeName: includePolygonName,
			includeAnalysis: includeAnalysis,
		});
	};

	if (!isOpen) return null;

	return (
		<Modal
			size="lg"
			centered
			show={isOpen}
			onHide={() => onResolve()}
		>
			<Modal.Header closeButton>
				<Modal.Title>Downloading dataset</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Col>
					Select the training models that will be included on the dataset:
					<Col className="mt-2">
						<Form.Control
							placeholder="Filter by training model name..."
							value={search}
							onChange={(e) => setSearch(e.target.value)}
						/>
					</Col>
				</Col>
				<Col className="p-2">
					<Form.Check
						checked={allVisibleSelected}
						onChange={(e) => selectAllVisible(e.target.checked)}
						id="SelectAll"
						label="Select all"
					/>
					{[...filteredTrialList].map((ft) => (
						<Form.Check
							key={`checkbox-${ft.id}`}
							id={ft?.id?.toString()}
							checked={selectedTrials[ft?.id?.toString()]}
							onChange={(e) =>
								setSelectedTrials((s) => ({
									...s,
									[ft?.id?.toString()]: e.target.checked,
								}))
							}
							label={`${ft.id} - ${ft.name}`}
						/>
					))}
				</Col>
			</Modal.Body>
			<Modal.Footer className="d-flex justify-content-between">
				<div>
					<Form.Check
						id="IncludePolygonName"
						checked={includePolygonName}
						onChange={(e) => setIncludePolygonName(e.target.checked)}
						label="Include additional info (polygon name, trial name and assessment date)"
					/>
					<Form.Check
						checked={includeAnalysis}
						onChange={(e) => setIncludeAnalysis(e.target.checked)}
						id="IncludeAnalysis"
						label="Include analysis"
					/>
				</div>
				<span className="d-flex gap-3">
					<Button
						variant="secondary"
						onClick={() => onResolve()}
					>
						Cancel
					</Button>
					<Button
						disabled={idsFiltrados.length < 1}
						variant="success"
						onClick={submit}
					>
						Download
					</Button>
				</span>
			</Modal.Footer>
		</Modal>
	);
};

export default create(ModalDownloadDataset);
