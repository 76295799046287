import React from "react";
import Chart from "react-apexcharts";

const MultiLineChart = ({ series, categories, points }) => {
	const options = {
		annotations: {
			points,
		},
		chart: {
			type: "line",
			toolbar: {
				show: false,
			},
			zoom: {
				enabled: false,
			},
		},
		plotOptions: {},
		stroke: {
			width: 4,
			curve: "smooth",
		},
		grid: {
			row: {
				colors: ["#fff", "#f2f2f2"],
			},
		},
		xaxis: {
			categories,
			tickPlacement: "on",
			labels: {
				formatter: (v) => {
					return v?.replaceAll("-", "/");
				},
			},
		},
		dataLabels: {
			enabled: false,
		},
		markers: {
			size: [6],
		},
		yaxis: {
			labels: {
				formatter: (v) => v.toFixed(2),
			},
		},
	};

	return (
		<div
			className="w-100 d-flex"
			style={{ height: "45vh" }}
		>
			<div className="w-100">
				<Chart
					options={options}
					series={series}
					type="line"
					width={"100%"}
					height={"100%"}
				/>
			</div>
		</div>
	);
};

export default MultiLineChart;
