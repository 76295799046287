import React, { useState, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { FaTrash } from "react-icons/fa";
import { toast } from "react-toastify";
import { Button, Col, Form, Spinner } from "react-bootstrap";

import Api from "../../services/api";

import ModalNewTrialMap from "./components/ModalNewTrialMap";
import ConfirmacaoAsync from "../../components/ConfirmacaoAsync";

import { ControllerCard } from "../Trials/styles";

import * as S from "./styles";

export default function FieldMap() {
	const history = useHistory();
	const [showModal, setShowModal] = useState(false);
	const [search, setSearch] = useState("");
	const [saving, setSaving] = useState(false);

	const [loadingInformation, setLoadingInformation] = useState(true);

	const [fieldMapList, setFieldMapList] = useState([]);

	const handleSave = async (newFieldMap) => {
		if (!newFieldMap.fm_name?.trim())
			return toast.error("Make shure to fill the field map name!");

		if (newFieldMap.fm_n_treatments < 1)
			return toast.error("The number of treatments must be greater than zero!");

		if (newFieldMap.fm_n_repetitions < 1)
			return toast.error(
				"The number of repetitions must be greater than zero!"
			);

		setSaving(true);
		await Api.post("/field-map", newFieldMap)
			.then((res) => {
				toast.success("Trial map created successfully!");

				setFieldMapList([...fieldMapList, res.data]);
				setShowModal(false);
			})
			.catch((err) => {
				console.log(err);
				toast.error("There was an error!");
			})
			.finally(() => {
				setSaving(false);
			});
	};

	const handleDelete = async (item) => {
		const confirmation = await ConfirmacaoAsync({
			title: "Delete this trial map?",
			message: "Are you sure want to delete this trial map permanently?",
		});

		if (confirmation) {
			try {
				await Api.delete(`/field-map/${item.fm_id}`);
				setFieldMapList((s) => s.filter((fm) => fm.fm_id !== item.fm_id));
				toast.success("Trial map deleted successfully!");
			} catch (error) {
				console.log(error);
				toast.error("There was an error deleting the trial map!");
			}
		}
	};

	const filteredFieldMapList = useMemo(
		() =>
			fieldMapList.filter(
				(t) => t.fm_name.toUpperCase().indexOf(search.toUpperCase()) !== -1
			),
		[fieldMapList, search]
	);

	useEffect(() => {
		const fetch = async () => {
			setLoadingInformation(true);
			await Api.get("/field-map")
				.then((res) => {
					setFieldMapList(res.data);
				})
				.catch((err) => {
					console.log("err> ", err);
					toast.error(err.message);
					setFieldMapList([]);
				});
			setLoadingInformation(false);
		};
		fetch();
	}, []);

	return (
		<S.Container>
			<S.ControllerContainer>
				<Col className="d-flex w-50 align-items-center">
					<Col
						className="d-flex flex-column w-100"
						md="auto"
					>
						<Col className="w-100 d-flex mb-2">
							<Button
								variant="success"
								onClick={() => setShowModal(true)}
							>
								{`New Field Map +`}
							</Button>
						</Col>

						<Col md={12}>
							<Form.Control
								placeholder={"Search by name..."}
								onChange={(e) => {
									setSearch(e.target.value);
								}}
								value={search}
							/>
						</Col>
					</Col>
				</Col>
				<Col className="d-flex w-50 justify-content-end align-items-center">
					<ControllerCard>
						<span>
							{loadingInformation ? "-" : filteredFieldMapList.length}
						</span>
						<div className="subtitle"> Field map(s) </div>
					</ControllerCard>
				</Col>
			</S.ControllerContainer>

			{loadingInformation ? (
				<Col className="mt-5 p-5">
					<Spinner
						animation="border"
						variant="success"
					/>
				</Col>
			) : (
				<S.TableContainer>
					{filteredFieldMapList?.length < 1 ? (
						<span style={{ marginTop: "24px" }}>No field map found</span>
					) : (
						<S.CustomTable>
							<thead>
								<tr>
									<th>Name</th>
									<th>Treatment(s)</th>
									<th>Repetition(s)</th>
									<th />
								</tr>
							</thead>
							<tbody>
								{filteredFieldMapList.map((item) => (
									<tr
										onClick={() => {
											history.push("/field-items", item);
										}}
										key={`item-trial-map-${item.fm_id}`}
									>
										<td>{item.fm_name}</td>
										<td>{item.fm_n_treatments}</td>
										<td>{item.fm_n_repetitions}</td>
										<td>
											<Button
												size="sm"
												variant="danger"
												onClick={(e) => {
													e.stopPropagation();
													handleDelete(item);
												}}
											>
												<FaTrash color="white" />
											</Button>
										</td>
									</tr>
								))}
							</tbody>
						</S.CustomTable>
					)}
				</S.TableContainer>
			)}

			{showModal ? (
				<ModalNewTrialMap
					show={showModal}
					close={() => setShowModal(false)}
					handleSave={handleSave}
					saving={saving}
				/>
			) : null}
		</S.Container>
	);
}
