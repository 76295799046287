import React from "react";
import { useMemo } from "react";
import Chart from "react-apexcharts";
import regression from "regression";

const calcRegression = (data) => {
	const result = regression.linear(data.map((d, idx) => [idx, +d]));

	return result.points.map((p) => p[1]);
};

const ScatterChart = ({ series: data, categories, points, max }) => {
	const series = useMemo(() => {
		return [
			...data.map((i) => ({ ...i, type: "scatter" })),
			{
				type: "line",
				name: "Regression",
				data: calcRegression(data[0].data || []),
			},
		];
	}, [data]);

	const options = {
		annotations: {
			points,
		},
		chart: {
			type: "line",
			stacked: false,
			toolbar: {
				show: false,
			},
			zoom: {
				enabled: false,
			},
		},
		plotOptions: {},
		stroke: {
			width: 4,
			curve: "smooth",
		},
		grid: {
			row: {
				colors: ["#fff", "#f2f2f2"],
			},
		},
		xaxis: {
			categories,
			tickPlacement: "on",
		},
		yaxis: {
			labels: {
				formatter: (v) => v.toFixed(2),
			},
			max: max,
		},
		markers: {
			size: [6, 0],
		},
		tooltip: {
			shared: true,
			intersect: false,
		},
	};

	return (
		<div
			className="w-100 d-flex"
			style={{ height: "45vh" }}
		>
			<div className="w-100">
				<Chart
					options={options}
					series={series}
					type="line"
					width={"100%"}
					height={"100%"}
				/>
			</div>
		</div>
	);
};

export default ScatterChart;
