import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { FiLogOut } from "react-icons/fi";
import { Button } from "react-bootstrap";

import { sidebarData } from "./sidebarData";

import Logotipo from "../../assets/logo.svg";

import * as S from "./styles";

function Sidebar() {
	const location = useLocation();
	const token = localStorage.getItem("token");

	const [selected, setSelected] = useState("");

	useEffect(() => {
		setSelected(location.pathname);
	}, [location.pathname]);

	const logout = () => {
		localStorage.removeItem("token");
		document.location.reload(true);
	};

	if (!token) return null;

	return (
		<S.Container>
			<S.LogoContainer>
				<S.Logo src={Logotipo} />
			</S.LogoContainer>
			<S.SidebarList>
				{sidebarData.map((item, index) => {
					const color = selected?.includes(item.link)
						? "var(--fmc-red)"
						: "black";
					return (
						<li key={`sidebar-item-${index}`}>
							<S.SidebarListItem
								selected={selected?.includes(item.link)}
								to={item.link}
							>
								<S.IconContainer>
									<S.IconStyle
										src={item.icon}
										color={color}
									/>
								</S.IconContainer>
								<b
									style={{
										marginTop: "3px",
										color,
									}}
								>
									{" "}
									{item.title}{" "}
								</b>
							</S.SidebarListItem>
						</li>
					);
				})}
			</S.SidebarList>
			<S.LogoutContainer onClick={logout}>
				<Button
					className="d-flex align-items-center justify-content-center gap-1"
					variant="light"
				>
					Logout
					<FiLogOut style={{ margin: "0 6px" }} />
				</Button>
			</S.LogoutContainer>
		</S.Container>
	);
}

export default Sidebar;
