import React, { useEffect, useRef, useState } from "react";
import { Stage, Layer, Rect, Image as Img, Group, Text } from "react-konva";
import { LoopCircleLoading } from "react-loadingg";
import ProgressBar from "@ramonak/react-progress-bar";

import * as S from "./styles";

const URLImage = ({
	imageScale,
	image,
	x,
	y,
	isSelected,
	onSelect,
	setImage,
	originalW,
	originalH,
	setRectangles,
	lockSamples,
}) => {
	const imgRef = useRef();
	const trRef = useRef();

	useEffect(() => {
		if (isSelected) {
			trRef.current?.nodes([imgRef.current]);
			trRef.current?.getLayer().batchDraw();
		}
	}, [isSelected]);

	useEffect(() => {
		setImage((s) => ({
			...s,
			scale: Number(imageScale),
		}));
		imgRef.current?.width(originalW * imageScale);
		imgRef.current?.height(originalH * imageScale);
	}, [imageScale, originalH, originalW, setImage]);

	return (
		<>
			<Img
				ref={imgRef}
				x={x}
				y={y}
				draggable
				image={image}
				onClick={onSelect}
				onDragMove={(e) => {
					let offsetX = 0;
					let offsetY = 0;
					setImage((s) => {
						offsetX = e.target.x() - s.x;
						offsetY = e.target.y() - s.y;

						if (lockSamples)
							setRectangles((s) =>
								s.map((rect) => ({
									...rect,
									x: rect.x + offsetX,
									y: rect.y + offsetY,
								}))
							);
						return { ...s, x: e.target.x(), y: e.target.y() };
					});

					imgRef.current.getLayer().batchDraw();
				}}
				onTransformEnd={(e) => {
					setImage((s) => ({
						...s,
						x: e.target.x(),
						y: e.target.y(),
						scale: (e.target.width() * e.target.scaleX()) / originalW,
					}));
				}}
			/>
		</>
	);
};

const Rectangle = ({ shapeProps, isSelected, onSelect, onChange }) => {
	const shapeRef = useRef();

	return (
		<>
			<Group
				x={shapeProps.x}
				y={shapeProps.y}
				width={shapeProps.width}
				height={shapeProps.height}
				onClick={onSelect}
				onTap={onSelect}
				ref={shapeRef}
				draggable
				onDragEnd={(e) => {
					onChange({
						...shapeProps,
						x: e.target.x(),
						y: e.target.y(),
					});
					shapeRef.current.getLayer().batchDraw();
				}}
			>
				<Rect
					width={shapeProps.width}
					height={shapeProps.height}
					fill={isSelected ? "#2e87e790" : "#ffffff90"}
					stroke={isSelected ? "#2e87e7" : "yellow"}
				/>
				<Text
					text={shapeProps.id}
					align="center"
					verticalAlign="middle"
					width={shapeProps.width}
					height={shapeProps.height}
					color={isSelected ? "white" : "black"}
					fontStyle="bold"
					fontSize={12}
				/>
			</Group>
		</>
	);
};

const Konva = ({
	image,
	imageScale,
	rectangles,
	setRectangles,
	layerRef,
	handleAddRectangle,
	setImage,
	loading,
	imgUploadProgress,
	imgDownloadProgress,
	lockSamples = false,
}) => {
	const [selectedId, selectShape] = useState(null);
	const stageRef = useRef(null);

	const [konvaDimensions, setKonvaDimensions] = useState({
		w: 800,
		h: 500,
	});

	const checkDeselect = (e) => {
		// deselect when clicked on empty area
		const clickedOnEmpty = e.target === e.target.getStage();
		if (clickedOnEmpty) {
			selectShape(null);
		}
	};

	// Keyboard listeners
	useEffect(() => {
		function listenKeyboard(e) {
			if (selectedId) {
				// LEFT
				if (e.keyCode === 37) {
					setRectangles((s) =>
						s.map((rect) =>
							rect.id !== selectedId
								? rect
								: {
										...rect,
										x: rect.x - 5,
								  }
						)
					);
				} else if (e.keyCode === 38) {
					// UP
					setRectangles((s) =>
						s.map((rect) =>
							rect.id !== selectedId
								? rect
								: {
										...rect,
										y: rect.y - 5,
								  }
						)
					);
				} else if (e.keyCode === 39) {
					// RIGHT
					setRectangles((s) =>
						s.map((rect) =>
							rect.id !== selectedId
								? rect
								: {
										...rect,
										x: rect.x + 5,
								  }
						)
					);
				} else if (e.keyCode === 40) {
					// DOWN
					setRectangles((s) =>
						s.map((rect) =>
							rect.id !== selectedId
								? rect
								: {
										...rect,
										y: rect.y + 5,
								  }
						)
					);
				} else if (e.keyCode === 46) {
					// DELETE
					setRectangles((s) => s.filter((rect) => rect.id !== selectedId));
				} else {
					return;
				}
			}
			e.preventDefault();
		}

		if (stageRef.current) {
			const container = stageRef.current.container();

			container.tabIndex = 1;
			// container.focus();

			container.addEventListener("keydown", listenKeyboard);

			return () => {
				container.removeEventListener("keydown", listenKeyboard);
			};
		}
	}, [stageRef, selectedId, setRectangles]);

	// Resize listener and prevent context menu open
	useEffect(() => {
		setTimeout(() => {
			const konvaContainer = document.getElementById("konva-container");

			const handleResize = () => {
				if (konvaContainer) {
					setKonvaDimensions({
						w: konvaContainer.clientWidth,
						h: konvaContainer.clientHeight,
					});
				}
			};

			// Prevent context menu to show after right-clicking konva
			if (konvaContainer)
				konvaContainer.addEventListener("contextmenu", (event) =>
					event.preventDefault()
				);

			// First time rendering
			handleResize();

			// Listening to window resize
			window.addEventListener("resize", handleResize, false);
			return () => {
				window.removeEventListener("resize", handleResize, false);
			};
		}, 400);
	}, []);

	if (imgDownloadProgress > 0) {
		return (
			<S.Container>
				<S.SpinnerLoading>
					<p style={{ marginBottom: "12px" }}>Loading image...</p>
					<ProgressBar
						width="300px"
						completed={imgDownloadProgress}
						bgColor="#198754"
						labelColor="#ffffff"
						animateOnRender
					/>{" "}
				</S.SpinnerLoading>
			</S.Container>
		);
	}

	if (imgUploadProgress > 0) {
		return (
			<S.Container>
				<S.SpinnerLoading>
					<p style={{ marginBottom: "12px" }}>Uploading image...</p>
					<ProgressBar
						width="300px"
						completed={imgUploadProgress}
						bgColor="#198754"
						labelColor="#ffffff"
						animateOnRender
					/>{" "}
				</S.SpinnerLoading>
			</S.Container>
		);
	}

	return (
		<S.Container onContextMenu={() => false}>
			{loading.image || loading.imageURL || loading.locations ? (
				<S.SpinnerLoading>
					<LoopCircleLoading />
				</S.SpinnerLoading>
			) : (
				<>
					{image.src ? (
						<Stage
							onContextMenu={() => false}
							ref={stageRef}
							width={konvaDimensions.w}
							height={konvaDimensions.h}
							style={{ background: "#f8f8f8" }}
							onMouseDown={checkDeselect}
							onTouchStart={checkDeselect}
							onClick={handleAddRectangle}
						>
							<Layer
								imageSmoothingEnabled={false}
								ref={layerRef}
							>
								<URLImage
									imageScale={imageScale}
									image={image.src}
									setImage={setImage}
									isSelected={-1 === selectedId}
									onSelect={(e) => {
										e.evt.button === 0 && selectShape(-1);
									}}
									x={image.x}
									y={image.y}
									originalW={image.originalW}
									originalH={image.originalH}
									setRectangles={setRectangles}
									lockSamples={lockSamples}
								/>
								{rectangles.map((rect, i) => {
									return (
										<Rectangle
											key={`${i}-${rect.x}-${rect.y}`}
											shapeProps={rect}
											isSelected={rect.id === selectedId}
											onSelect={(e) => {
												e.evt.button === 0 && selectShape(rect.id);
											}}
											handle={() => {}}
											onChange={(newAttrs) => {
												const rects = rectangles.slice();
												rects[i] = newAttrs;
												setRectangles(rects);
											}}
										/>
									);
								})}
							</Layer>
						</Stage>
					) : (
						<div>
							<h5
								style={{
									padding: "24px",
									opacity: 0.7,
									textAlign: "center",
								}}
							>
								Start by adding an image using the "New Image" button
							</h5>
						</div>
					)}
				</>
			)}
		</S.Container>
	);
};

export default Konva;
