
import SvgPlant from "../../assets/plant-icon.svg";
import SvgUser from "../../assets/user-icon.svg";
import SvgAnalytics from "../../assets/analytics-icon.svg";
import SvgGrid from "../../assets/grid-icon.svg";
import SvgHome from "../../assets/home-icon.svg";


export const sidebarData = [
  {
    title: "Home",
    icon: SvgHome,
    font: "MaterialCommunityIcons",
    link: "/home",
  },
  {
    title: "Trials",
    icon: SvgPlant,
    font: "MaterialCommunityIcons",
    link: "/trial",
  },
  {
    title: "Users",
    icon: SvgUser,
    font: "MaterialCommunityIcons",
    link: "/users",
    style: {
      width: "28px",
    },
  },
  {
    title: "Training Models",
    icon: SvgAnalytics,
    font: "MaterialCommunityIcons",
    link: "/training-model",
    style: {
      width: "28px",
    },
  },
  {
    title: "Field Map",
    icon: SvgGrid,
    font: "MaterialCommunityIcons",
    link: "/field-map",
    style: {
      width: "28px",
    },
  },
];
