import styled from "styled-components";
import { flexCenter } from "../../pages/Login/styles";

export const DataCardContainer = styled.div`
  display: flex;
  flex-direction: column; 
  overflow: auto;
  height: 100%; 
  width: 100%;

`;


export const DataCard = styled.div`
  display: flex;
  width: calc(100% - 100px);
  margin: 50px;
  background: #ffffff;
  box-shadow: 0px 2px 10px rgb(0 0 0 / 20%);
  border-radius: 10px;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: flex-start;
`;

export const BlockContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${({ bg }) => bg || 'white'};
  border-radius: 12px;
  opacity: ${({ opacity }) => opacity}; 

  ${flexCenter}
  flex-direction: column;
  span  { 
    color: #f1f1f1;   
}
`;


export const CustomTable = styled.table`
  border-collapse: separate;
  border-spacing: 0.2em;

  td {
  
    border-style: solid;
    border-width: 0;
      
    overflow: hidden; 
    word-break: normal; 

    
    width: 70px;
    height: 70px;
    text-align: center;
    vertical-align: middle;
    font-family: Archivo;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 15px;

    color: #767676;
  }
`;