import React from "react";
import {
	Document,
	Page,
	Text,
	View,
	StyleSheet,
	Image,
	Font,
} from "@react-pdf/renderer";

Font.register({
	family: "Ubuntu",
	fonts: [
		{
			src: "https://fonts.gstatic.com/s/ubuntu/v15/4iCv6KVjbNBYlgoC1CzTtw.ttf",
			fontWeight: 300,
		},
		{
			src: "https://fonts.gstatic.com/s/ubuntu/v15/4iCs6KVjbNBYlgo6eA.ttf",
			fontWeight: 400,
		},
		{
			src: "https://fonts.gstatic.com/s/ubuntu/v15/4iCv6KVjbNBYlgoCjC3Ttw.ttf",
			fontWeight: 500,
		},
		{
			src: "https://fonts.gstatic.com/s/ubuntu/v15/4iCv6KVjbNBYlgoCxCvTtw.ttf",
			fontWeight: 700,
		},
	],
});

// Create styles
const styles = StyleSheet.create({
	page: {
		backgroundColor: "white",
		fontFamily: "Ubuntu",
		margin: 10,
		padding: 10,
	},
	title: {
		width: "100%",
		textAlign: "center",
		fontSize: 15,
		marginBottom: 24,
		fontWeight: "bold",
	},
	headerText: {
		width: "100%",
		textAlign: "left",
		fontSize: 13,
		marginBottom: 8,
	},
	bold: {
		fontWeight: "bold",
	},
	containerTable: {
		width: "100%",
		display: "flex",
		flexDirection: "column",
	},
	containerChartImage: {
		width: "100%",
		display: "flex",
		alignItems: "center",
		flexDirection: "column",
		justifyContent: "center",
	},
	chartImage: {
		width: "95%",
		maxHeight: "300px",
		objectFit: "contain",
	},
	containerChart: {
		width: "73%",
		height: "100%",
		display: "flex",
		alignItems: "center",
		flexDirection: "column",
	},
	chartConfigContainer: {
		width: "100%",
		display: "flex",
		alignItems: "center",
		flexDirection: "column",
	},
	textConfig: {
		width: "100%",
		textAlign: "left",
		fontSize: 10,
		marginBottom: 4,
	},
	textAnalysis: {
		width: "100%",
		textAlign: "left",
		fontSize: 10,
		marginBottom: 12,
	},
	separator: {
		marginVertical: 12,
		width: "100%",
		height: 1,
		backgroundColor: "lightgrey",
	},
	pageNumber: {
		position: "absolute",
		fontSize: 12,
		bottom: 30,
		left: 0,
		right: 0,
		textAlign: "center",
		color: "grey",
	},
});

// Create Document Component
const PDFExport = ({
	trial, // Object with trial info
	chartAUDPCImage, // b64
	chartDiseaseImage, // b64
	selectedTreatments, // T1, T2 ... TN
}) => (
	<Document>
		<Page
			size="A4"
			style={styles.page}
		>
			<Text style={styles.title}>{trial?.name} - AUDPC</Text>
			<Text style={styles.headerText}>
				Crop: <Text style={styles.bold}>{trial?.crop}</Text>
			</Text>
			<Text style={styles.headerText}>
				Season: <Text style={styles.bold}>{trial?.season}</Text>
			</Text>

			<View style={styles.separator} />

			<View style={styles.containerChartImage}>
				<Text
					style={{
						...styles.headerText,
						fontWeight: "bold",
					}}
				>
					AUDPC Chart
				</Text>
				<Image
					style={styles.chartImage}
					src={chartAUDPCImage}
				/>
			</View>

			{selectedTreatments?.length > 0 ? (
				<View style={{ ...styles.containerChartImage, marginTop: 20 }}>
					<Text
						style={{
							...styles.headerText,
							fontWeight: "bold",
						}}
					>
						Disease Progress Curve Chart
					</Text>
					<View style={styles.chartConfigContainer}>
						<Text style={styles.textConfig}>
							Treatments:{" "}
							<Text style={styles.bold}>{selectedTreatments.join(", ")}</Text>
						</Text>
					</View>
					<Image
						style={styles.chartImage}
						src={chartDiseaseImage}
					/>
				</View>
			) : null}

			<Text
				style={styles.pageNumber}
				render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
				fixed
			/>
		</Page>
	</Document>
);

export default PDFExport;
