import styled from "styled-components";
import { flexCenter } from "../Login/styles";
import { FiSettings } from "react-icons/fi";

export const Container = styled.div`
  ${flexCenter}
  padding: 2em;
  gap: 2em;
`;

export const ChartContainer = styled.div`
  ${flexCenter}
    
  width: 50%;
  height: 72vh;
  flex-direction: column;

  animation: fade-in .5s ease-in;

  border: 1px solid #e1e1e1;
  border-radius: 0.5em;
padding: 1em;
`;

export const ChartWrapper = styled.div`
  position: relative;
  padding: 1em;
  flex: 1; 
  width: 100%; 
`;

export const IconContainer = styled.div`
  width: 20px;
  height: 20px;
  position: absolute;
  top: .35em;
  right: .35em;
  cursor: pointer;
`;

export const IconChart = styled(FiSettings)`
  color: gray;
  width: 20px;
  height: 20px;

  &:hover {
    color: #424242;
  }

  transition: all .3s ease-in-out;
`;