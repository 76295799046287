import React, { useState, useEffect, useMemo, useRef } from "react";
import { useHistory } from "react-router-dom";
import { FaEdit, FaTrash } from "react-icons/fa";
import { toast } from "react-toastify";
import { CSVLink } from "react-csv";

import Api from "../../services/api";

import { format, parse } from "date-fns";
import { Button, ButtonGroup, Col, Form, Spinner } from "react-bootstrap";
import { assessmentTypeList } from "../TrialAssessment/helper";
import ModalDownloadDataset from "./components/ModalDownloadDataset";

import ModalFormTrial from "./components/ModalFormTrial";
import ConfirmacaoAsync from "../../components/ConfirmacaoAsync";

import * as S from "./styles";

export default function Trials({ training }) {
	const history = useHistory();

	const [loadingInformation, setLoadingInformation] = useState(true);

	const [showModalNewTrial, setShowModalNewTrial] = useState(false);
	const [modalEditTrial, setModalEditTrial] = useState({
		show: false,
		data: undefined,
		hasFieldMap: false,
	});

	const [imageType, setImageType] = useState("multispectral");
	const [search, setSearch] = useState("");
	const [dataset, setDataset] = useState([]);
	const [loadingDataset, setLoadingDataset] = useState(false);
	const linkCSV = useRef();

	const trialType = useMemo(
		() => (training ? "training model" : "trial"),
		[training]
	);

	const [trialList, setTrialList] = useState([]);

	const [savingTrial, setSavingTrial] = useState(false);

	const handleCreateTrial = async (info) => {
		setSavingTrial(true);
		var toSave = {
			...info,
			training_model: training,
			image_type: training ? "multispectral" : imageType,
		};

		try {
			const { data } = await Api.post("/trial", toSave);
			setTrialList((s) => [...s, data]);

			toast.success("Trial created successfully!");

			setShowModalNewTrial(false);

			history.push(`/${!training ? "trial" : "training-model"}/assessment`, {
				...data,
				training,
			});
		} catch (err) {
			console.log(err);
			toast.error("Error!");
		} finally {
			setSavingTrial(false);
		}
	};

	const handleEditTrial = async (info) => {
		setSavingTrial(true);

		try {
			await Api.put("/trial", info);
			setTrialList((s) =>
				s.map((trial) => (trial.id !== info.id ? trial : { ...trial, ...info }))
			);

			toast.success("Trial saved successfully!");

			setModalEditTrial({ show: false, data: undefined });
		} catch (err) {
			console.log(err);
			toast.error("Error!");
		} finally {
			setSavingTrial(false);
		}
	};

	const handleDelete = async (item) => {
		const confirmation = await ConfirmacaoAsync({
			message: `Are you sure want to delete this ${trialType}?`,
			title: `Delete this ${trialType}?`,
		});

		if (confirmation) {
			await Api.delete("/trial", {
				data: { id: item.id },
			})
				.then((res) => {
					var newArr = trialList.filter((trial) => trial.id !== item.id);
					setTrialList(newArr);
					toast.success(`The ${trialType} was deleted successfully!`);
				})
				.catch((err) => {
					console.log(err);
					toast.error("Error!");
				});
		}
	};

	const downloadDataset = async () => {
		try {
			const { trialIds, includeName, includeAnalysis } =
				(await ModalDownloadDataset({
					trialList: trialList,
				})) || {};

			if (trialIds?.length) {
				setLoadingDataset(true);

				const { data } = await Api.get("/training-dataset", {
					params: {
						trialIds,
						includeName,
						includeAnalysis,
					},
				});

				setDataset(data);

				setTimeout(() => {
					linkCSV.current?.link?.click();
				}, 100);
			}
		} catch (error) {
			console.log(error);
			toast.error("There was an error loading the dataset.");
		}
		setLoadingDataset(false);
	};

	const filteredTrialList = useMemo(
		() =>
			trialList.filter(
				(t) =>
					t.name.toUpperCase().indexOf(search.toUpperCase()) !== -1 &&
					t.image_type === imageType
			),
		[trialList, search, imageType]
	);

	useEffect(() => {
		const fetch = async () => {
			setLoadingInformation(true);
			try {
				const { data } = await Api.get("/trial", {
					params: {
						training_model: !!training,
					},
				});
				setTrialList(data);
			} catch (err) {
				console.log("err> ", err);
				toast.error(err.message);
			}
			setLoadingInformation(false);
		};
		fetch();
	}, [training]);

	return (
		<S.Container>
			<S.ControllerContainer>
				<Col className="d-flex w-50 align-items-center">
					<Col
						className="d-flex flex-column w-100"
						md="auto"
					>
						<Col className="w-100 d-flex mb-2">
							<Button
								variant="success"
								onClick={() => setShowModalNewTrial(true)}
							>
								{`New ${trialType} +`}
							</Button>

							{training ? (
								<>
									<Button
										disabled={loadingDataset}
										variant="secondary"
										style={{
											padding: "0 12px",
											marginLeft: "12px",
											width: "fit-content",
										}}
										onClick={downloadDataset}
									>
										{loadingDataset
											? "Loading dataset..."
											: "Download dataset (.csv)"}
									</Button>
									<CSVLink
										ref={linkCSV}
										filename="training-dataset"
										data={dataset}
										separator=";"
									/>
								</>
							) : null}
						</Col>

						<Col md={12}>
							<Form.Control
								placeholder={`Search by ${trialType} name...`}
								onChange={(e) => {
									setSearch(e.target.value);
								}}
								value={search}
							/>
						</Col>
					</Col>
				</Col>
				{training ? (
					<Col className="d-flex w-50 justify-content-end align-items-center">
						<S.ControllerCard>
							<span>{loadingInformation ? "-" : filteredTrialList.length}</span>
							<div className="subtitle"> Training model(s) </div>
						</S.ControllerCard>
					</Col>
				) : (
					<Col className="d-flex w-50 justify-content-end align-items-center">
						<S.ControllerCard>
							<span>{loadingInformation ? "-" : filteredTrialList.length}</span>
							<div className="subtitle"> Trials </div>
						</S.ControllerCard>
						<S.ControllerCard>
							<span>{loadingInformation ? "-" : 0}</span>
							<div className="subtitle"> Complete </div>
						</S.ControllerCard>
						<S.ControllerCard>
							<span>{loadingInformation ? "-" : filteredTrialList.length}</span>
							<div className="subtitle"> In Progress </div>
						</S.ControllerCard>
					</Col>
				)}
			</S.ControllerContainer>

			{!training ? (
				<Col
					style={{ maxHeight: "62px" }}
					className="d-flex w-100 px-4 pt-4"
				>
					<ButtonGroup>
						<Button
							onClick={() => setImageType("multispectral")}
							variant={imageType === "multispectral" ? "danger" : "secondary"}
						>
							Multispectral
						</Button>
						<Button
							onClick={() => setImageType("rgb")}
							variant={imageType === "rgb" ? "danger" : "secondary"}
						>
							RGB
						</Button>
					</ButtonGroup>
				</Col>
			) : null}

			{loadingInformation ? (
				<Col className="mt-5 p-5">
					<Spinner
						animation="border"
						variant="success"
					/>
				</Col>
			) : (
				<S.TableContainer>
					{filteredTrialList?.length < 1 ? (
						<span style={{ marginTop: "24px" }}>No {trialType} found</span>
					) : (
						<S.CustomTable>
							<thead>
								<tr>
									<th>ID</th>
									<th>Name</th>
									{!training ? <th>Crop</th> : <th>Target</th>}
									{!training ? (
										<>
											<th>Sowing date</th>
											<th>Season</th>
										</>
									) : null}
									<th />
								</tr>
							</thead>
							<tbody>
								{filteredTrialList.map((item) => (
									<tr
										onClick={() => {
											history.push(
												`/${!training ? "trial" : "training-model"}/assessment`,
												{
													...item,
													training,
												}
											);
										}}
										key={`item-ensaio-${item.id}`}
									>
										<td>{item.id}</td>
										<td>{item.name}</td>

										{!training ? (
											<td>{item.crop}</td>
										) : (
											<td>
												{
													assessmentTypeList.find(
														(t) => t.value === item.target
													)?.name
												}
											</td>
										)}

										{!training ? (
											<>
												<td>
													{format(
														parse(item.sowing_date, "yyyy-MM-dd", new Date()),
														training ? "dd/MM/yyyy" : "MM/dd/yyyy"
													)}
												</td>
												<td>{item.season}</td>
											</>
										) : null}
										<td>
											<Col className="d-flex gap-2 justify-content-end align-items-center px-4">
												<Button
													size="sm"
													variant="success"
													onClick={(e) => {
														e.stopPropagation();
														setModalEditTrial({
															show: true,
															data: item,
															hasFieldMap: item.hasFieldMap,
														});
													}}
												>
													<FaEdit color="white" />
												</Button>
												<Button
													size="sm"
													variant="danger"
													onClick={(e) => {
														e.stopPropagation();
														handleDelete(item);
													}}
												>
													<FaTrash color="white" />
												</Button>
											</Col>
										</td>
									</tr>
								))}
							</tbody>
						</S.CustomTable>
					)}
				</S.TableContainer>
			)}

			{showModalNewTrial ? (
				<ModalFormTrial
					exibir
					editing={false}
					fechar={() => setShowModalNewTrial(false)}
					savingTrial={savingTrial}
					training={training}
					handleSave={handleCreateTrial}
				/>
			) : null}

			{modalEditTrial.show ? (
				<ModalFormTrial
					exibir
					editing
					oldInfo={modalEditTrial.data}
					fechar={() => setModalEditTrial(false)}
					savingTrial={savingTrial}
					training={training}
					handleSave={handleEditTrial}
					hasFieldMap={modalEditTrial.hasFieldMap}
				/>
			) : null}
		</S.Container>
	);
}
