import React, {
	useState,
	useEffect,
	useRef,
	useCallback,
	useMemo,
} from "react";
import { toast } from "react-toastify";
import { FaCopy, FaImage, FaSave, FaTrash, FaUndo } from "react-icons/fa";
import { RangeStepInput } from "react-range-step-input";
import { Button, Col, Form, Spinner } from "react-bootstrap";
import _ from "lodash";

import Api from "../../services/api";
import Konva from "../Konva";

import { encapsulateArray } from "../../util/arrayHandler";
import ModalCopyConfiguration from "../ModalCopyConfiguration";

import { DataTitle } from "../ApplicationMap/styles";

import * as S from "./styles";

const SAMPLE_COLUMN_GAP = 40;
const SAMPLE_ROW_GAP = 40;

function Assessment(props) {
	const fileInputRef = useRef(null);

	// Global
	const [content, setContent] = useState(props.content);
	const [assessments, setAssessments] = useState(props.assessments);
	const [assessment_id, setAssessment_id] = useState(props.assessment_id);
	const [loading, setLoading] = useState({
		locations: false,
		image: false,
	});
	const [selected_date, setSelected_date] = useState(props.selected_date);
	const [lockSamples, setLockSamples] = useState(false);

	// Konva states
	const [imageScale, setImageScale] = useState(1);
	const [sampleName, setSampleName] = useState("101");
	const [scale, setScale] = useState(1);
	const [locationsLoaded, setLocationsLoaded] = useState(false);
	const [rectangles, setRectangles] = useState([]);
	const layerRef = useRef(null);
	const [savingSamples, setSavingSamples] = useState(false);

	const IMAGE_INITIAL_STATE = {
		src: undefined,
		x: 0,
		y: 0,
		originalW: 0,
		originalH: 0,
		scale: 0,
	};

	const [image, setImage] = useState(IMAGE_INITIAL_STATE);

	const getAllRectanglesRGBData = async (printBase64 = false) => {
		const uri = layerRef.current.toDataURL();

		const canvas = document.createElement("canvas");
		const konvaContainer = document.querySelector(".konva-container");
		canvas.width = konvaContainer?.clientWidth;
		canvas.height = konvaContainer?.clientHeight;
		const ctx = canvas.getContext("2d");

		const img = new Image();
		img.src = uri;

		return new Promise((res) => {
			img.onload = () => {
				ctx.drawImage(img, 0, 0);

				res(
					rectangles.map((rect) => {
						var pixels = ctx.getImageData(
							rect.x,
							rect.y,
							rect.width,
							rect.height
						);

						if (printBase64) {
							const resultCanvas = document.createElement("canvas");
							resultCanvas.width = pixels.width;
							resultCanvas.height = pixels.height;
							const resultCtx = resultCanvas.getContext("2d");
							resultCtx.putImageData(pixels, 0, 0);
						}

						var dataArray = pixels.data;
						var rgbArray = [];
						for (var i = 0; i < dataArray.length; i += 4) {
							rgbArray.push([dataArray[i], dataArray[i + 1], dataArray[i + 2]]);
						}
						return rgbArray;
					})
				);
			};
		});
	};

	function handleClear() {
		setRectangles([]);
		setSampleName("101");
	}

	function handleUndo() {
		setRectangles((s) => s.filter((_, idx) => idx !== s.length - 1));
		const previousSampleName = (Number(sampleName) - 1)?.toString();
		if (
			!Number.isNaN(Number(sampleName)) &&
			avaliableSampleNameList.includes(previousSampleName)
		)
			setSampleName(previousSampleName);
	}

	const handleSaveSample = async () => {
		try {
			setSavingSamples(true);

			await handleSaveScales();

			var locationsToSave = [];

			const rgbs = await getAllRectanglesRGBData();

			locationsToSave = rectangles.map((element, idx) => {
				return {
					assessment_id: assessment_id,
					x_position: element.x,
					y_position: element.y,
					name: element.id,
					path: "path/path",
					rgb_array: rgbs[idx],
				};
			});

			// Splitting requests on chunks
			const chunks = encapsulateArray(locationsToSave, locationsToSave.length);

			const newLocations = await Promise.all(
				chunks.map((chunk) => Api.post("/samples", chunk))
			);

			toast.success("Samples saved!");

			if (newLocations.some((res) => res.data.erroPlumber))
				toast.error(
					"Unable to perform statistical analysis, please try again later."
				);

			if (!!props?.goToAssessment) {
				props?.goToAssessment();
			}
		} catch (error) {
			console.log(error);
			const msg = error?.response?.data?.msg;
			if (msg?.trim()) toast.error(msg);
		}
		setSavingSamples(false);
	};

	const [imgUploadProgress, setImgUploadProgress] = useState(0);

	useEffect(() => {
		handleClear();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [assessment_id]);

	const handleSaveImage = async (e) => {
		try {
			const assessment = assessments.find((as) => as.id === assessment_id);
			if (!assessment)
				throw new Error("You must select an assessment to proceed.");

			setLoading((s) => ({ ...s, salvandoImagem: true }));
			const files = Array.from(e.target.files);
			var formData = new FormData();

			files.forEach((file) => {
				formData.append("imgsAssessment", file);
			});

			await Api.post(
				`/assessment/upload_${props.image_type}_img/${assessment_id}`,
				formData,
				{
					headers: {
						"content-type": "multipart/form-data",
					},
					onUploadProgress: (e) => {
						setImgUploadProgress(
							Number(((e.loaded / e.total) * 100)?.toFixed(0))
						);
					},
				}
			);

			await loadImage();

			setImgUploadProgress(0);

			setLoading((s) => ({ ...s, salvandoImagem: false }));
		} catch (error) {
			toast.error(
				error?.message || "There was an error during the image uploading"
			);
		}
		fileInputRef.current.value = "";
	};

	const handleSaveScales = async () => {
		let newArr = [...assessments];
		newArr[selected_date].marker_scale = scale;

		newArr[selected_date].img_scale =
			image.scale > 0 ? image.scale : newArr[selected_date].img_scale;
		newArr[selected_date].init_posx = image.x;
		newArr[selected_date].init_posy = image.y;
		const { img_path, ...data } = newArr[selected_date];
		await Api.put(`/assessment/${assessments[selected_date].id}`, data)
			.then((res) => {
				setAssessments(newArr);
			})
			.catch((err) => {
				console.log(err);
				toast.error("Scales not saved");
			});
	};

	const [imgDownloadProgress, setImgDownloadProgress] = useState(0);

	const avaliableSampleNameList = useMemo(() => {
		const n_repetitions = Number(props.n_repetitions || 0);
		const n_treatments = Number(props.n_treatments || 0);

		const existingLabels = rectangles.map((r) => r.id?.toString());

		let labels = Array.from({ length: n_repetitions }, (_, idxRepet) =>
			Array.from(
				{
					length: n_treatments,
				},
				(_, idxTreat) => {
					const treatmentLabel =
						idxTreat + 1 > 9 ? (idxTreat + 1)?.toString() : `0${idxTreat + 1}`;

					return `${idxRepet + 1}${treatmentLabel}`;
				}
			)
		)
			.flat()
			.filter((sname) => !existingLabels.includes(sname));

		if (labels.length > 0 && labels.length === n_repetitions * n_treatments)
			labels = ["All samples", ...labels];

		return labels;
	}, [rectangles, props.n_repetitions, props.n_treatments]);

	const loadImage = useCallback(async () => {
		const assessmentList = assessments;

		const getImage = async (URL) => {
			setLoading((s) => ({ ...s, image: true }));
			const image = new Image();
			image.src = URL;
			image.crossOrigin = "";
			await new Promise((res) => {
				image.onload = () => {
					const originalW = image.width;
					const originalH = image.height;
					if (assessmentList[selected_date].img_scale) {
						image.width *= assessmentList[selected_date].img_scale;
						image.height *= assessmentList[selected_date].img_scale;
						setImageScale(assessmentList[selected_date].img_scale);
					} else {
						const konvaContainer = document.querySelector(".konva-container");

						image.height =
							konvaContainer?.clientWidth * (image.height / image.width);
						image.width = konvaContainer?.clientWidth;
					}

					setImage({
						src: image,
						x: assessmentList[selected_date].init_posx
							? assessmentList[selected_date].init_posx
							: 0,
						y: assessmentList[selected_date].init_posy
							? assessmentList[selected_date].init_posy
							: 0,
						originalW,
						originalH,
					});

					res();
				};
			});

			setLoading((s) => ({ ...s, image: false, imageURL: false }));
		};

		if (assessmentList.length > 0 && assessment_id) {
			setImgUploadProgress(0);
			setLoading((s) => ({ ...s, imageURL: true }));
			await Api.get(`/image/${assessment_id}`, {
				onDownloadProgress: (e) => {
					setImgDownloadProgress(
						Number(((e.loaded / e.total) * 100)?.toFixed(0))
					);
				},
				responseType: "blob",
			})
				.then(async (res) => {
					var urlCreator = window.URL || window.webkitURL;
					var imageUrl = urlCreator.createObjectURL(res.data);
					await getImage(imageUrl);
					urlCreator.revokeObjectURL(imageUrl);
				})
				.catch((err) => {
					console.log("Error: ", err.response.data.msg);
				})
				.finally(() => {
					setImgDownloadProgress(0);
					setLoading((s) => ({ ...s, imageURL: false }));
				});
		}
	}, [assessment_id, assessments, selected_date]);

	const loadLocations = useCallback(
		async (givenScale = undefined) => {
			if (assessments.length > 0 && assessment_id) {
				setLoading((s) => ({ ...s, locations: true }));

				await Api.get(`/sample/${assessment_id}`)
					.then((res) => {
						const assessmentData = assessments.find(
							(dt) => dt.id === assessment_id
						);
						const newScale = givenScale || assessmentData?.marker_scale;

						setRectangles(
							res.data.map((loc) => ({
								x: loc.x_position,
								y: loc.y_position,
								width: 50 * (newScale || 1),
								height: 25 * (newScale || 1),
								fill: "#ffffff50",
								stroke: "yellow",
								id: loc.name,
							}))
						);
					})
					.catch((err) => {
						console.log("Error: ", err);
						toast.error("Error!");
					})
					.finally(() => {
						setLoading((s) => ({ ...s, locations: false }));
					});
			}
		},
		[assessments, assessment_id]
	);

	const [copyingConfiguration, setCopyingConfiguration] = useState(false);

	const copyAssessmentConfiguration = useCallback(async () => {
		try {
			const { assessment_id: origin_id } =
				(await ModalCopyConfiguration({
					assessmentList: assessments.filter((as) => as.id !== assessment_id),
				})) || {};

			if (origin_id > 0) {
				setCopyingConfiguration(true);

				const { data } = await Api.put("/sample/copy-config", {
					from: origin_id,
					to: assessment_id,
				});

				const newAssessments = assessments.map((as) => {
					if (Number(as.id) !== Number(assessment_id)) return as;
					return {
						...as,
						...data,
					};
				});

				setAssessments(newAssessments);
				props.setOriginalAssessments(newAssessments);

				setTimeout(() => {
					setScale(data.marker_scale);
					loadLocations(data.marker_scale);
				}, 100);
			}
		} catch (error) {
			console.log(error);
			toast.error("There was an error copying configuration.");
		}
		setCopyingConfiguration(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		assessments,
		assessment_id,
		rectangles,
		props.setOriginalAssessments,
		loadLocations,
		loadImage,
	]);

	const handleAddRectangle = useCallback(
		(e) => {
			if (
				avaliableSampleNameList.includes(sampleName) &&
				e.evt.button === 2 &&
				(e.target.nodeType === "Stage" || e.target.className === "Image")
			) {
				if (sampleName === "All samples") {
					const trialMap = [...avaliableSampleNameList];

					trialMap.shift();

					const chunks = _.chunk(trialMap, Number(props.n_treatments));

					chunks.forEach((line, idxLine) => {
						line.forEach((sample, idxSample) => {
							setRectangles((s) => [
								...s,
								{
									x:
										e.evt.offsetX -
										25 * scale +
										idxSample * (50 * scale + SAMPLE_COLUMN_GAP),
									y:
										e.evt.offsetY -
										12.5 * scale +
										idxLine * (25 * scale + SAMPLE_ROW_GAP),
									width: 50 * scale,
									height: 25 * scale,
									fill: "#ffffff50",
									stroke: "yellow",
									id: sample,
								},
							]);
						});
					});
				} else {
					setRectangles((s) => [
						...s,
						{
							x: e.evt.offsetX - 25 * scale,
							y: e.evt.offsetY - 12.5 * scale,
							width: 50 * scale,
							height: 25 * scale,
							fill: "#ffffff50",
							stroke: "yellow",
							id: sampleName,
						},
					]);
				}

				if (avaliableSampleNameList[rectangles.length > 0 ? 1 : 2])
					setSampleName(avaliableSampleNameList[rectangles.length > 0 ? 1 : 2]);
			}
		},
		[
			avaliableSampleNameList,
			props.n_treatments,
			rectangles.length,
			sampleName,
			scale,
		]
	);

	// Updating states based on props update
	useEffect(() => {
		setAssessments(props.assessments);
	}, [props.assessments]);

	useEffect(() => {
		setContent(props.content);
		setAssessments(props.assessments);
		setSelected_date(props.selected_date);
		setAssessment_id(props.assessment_id);
		setLocationsLoaded(false);
		setImage(IMAGE_INITIAL_STATE);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.content, props.selected_date, props.assessment_id]);

	// Updating scale on assessment change
	useEffect(() => {
		if (assessments.length > 0) {
			setScale(
				assessments[selected_date]?.marker_scale
					? assessments[selected_date]?.marker_scale
					: 1
			);
		}
	}, [assessments, selected_date]);

	// Loading previous rectangles/locations
	useEffect(() => {
		if (image && !locationsLoaded && assessment_id && !loading.salvandoImagem) {
			// Loading image and locations on assessment change (date change)
			loadImage();
			loadLocations();
			setLocationsLoaded(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [assessment_id]);

	// Updating rectangles/locations on scale change
	useEffect(() => {
		setRectangles(
			rectangles.map((loc) => ({
				x: loc.x - (50 * scale - loc.width) / 2,
				y: loc.y - (25 * scale - loc.height) / 2,
				width: 50 * scale,
				height: 25 * scale,
				fill: "#ffffff50",
				stroke: "yellow",
				id: loc.id,
			}))
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [scale]);

	const renderKonva = useMemo(
		() => (
			<Konva
				imageScale={imageScale}
				image={image}
				loading={loading}
				imgUploadProgress={imgUploadProgress}
				imgDownloadProgress={imgDownloadProgress}
				rectangles={rectangles}
				layerRef={layerRef}
				setRectangles={setRectangles}
				handleAddRectangle={(rec) =>
					avaliableSampleNameList.length > 0 ? handleAddRectangle(rec) : null
				}
				setImage={setImage}
				lockSamples={lockSamples}
			/>
		),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[
			copyingConfiguration,
			avaliableSampleNameList,
			imageScale,
			image,
			loading,
			imgUploadProgress,
			imgDownloadProgress,
			rectangles,
			handleAddRectangle,
			lockSamples,
		]
	);

	return (
		<Col
			className="d-flex h-100 flex-column p-4"
			style={{
				overflow: "auto",
			}}
		>
			{assessments.length < 1 ? (
				<DataTitle className="w-100 h-100 d-flex justify-content-center align-items-center">
					Start by adding an assessment using the menu on the left
				</DataTitle>
			) : (
				<>
					<Col style={{ maxHeight: "192px" }}>
						<S.Header>
							<Col md={3}>
								<h5 title={content.name}>{content.name}</h5>
								<div> Crop: {content.crop} </div>
								<div> Target: {content.target} </div>
								<div> Season: {content.season} </div>
							</Col>
							<Col md={5}>
								<Form.Group>
									<Form.Label>Sample name</Form.Label>
									<Form.Select
										size="sm"
										disabled={!avaliableSampleNameList.length}
										value={sampleName}
										onChange={(e) =>
											e.target.value && setSampleName(e.target.value)
										}
									>
										<option value={undefined}>Select...</option>
										{avaliableSampleNameList.map((sname) => (
											<option
												key={sname}
												value={sname}
											>
												{sname}
											</option>
										))}
									</Form.Select>
								</Form.Group>

								<Col className="d-flex gap-3 m-3 justify-content-center align-items-center">
									<input
										ref={fileInputRef}
										onChange={handleSaveImage}
										type="file"
										accept="image/png, image/jpeg, image/tiff"
										style={{ display: "none" }}
										multiple
									/>

									<Button
										size="sm"
										variant="success"
										disabled={
											copyingConfiguration ||
											savingSamples ||
											loading.salvandoImagem
										}
										onClick={copyAssessmentConfiguration}
										className="d-flex align-items-center gap-1"
									>
										<FaCopy />
										{copyingConfiguration ? "Copying..." : "Copy configuration"}
									</Button>

									<Button
										size="sm"
										variant="secondary"
										disabled={rectangles.length < 1}
										onClick={handleUndo}
										className="d-flex align-items-center gap-1"
									>
										<FaUndo />
										Undo
									</Button>
									<Button
										size="sm"
										variant="danger"
										onClick={handleClear}
										className="d-flex align-items-center gap-1"
									>
										<FaTrash />
										Clear
									</Button>
								</Col>
							</Col>
							<Col
								md={4}
								className="d-flex align-items-center justify-content-center"
							>
								<Col
									md={7}
									className="d-flex flex-column align-items-center justify-content-center"
								>
									<Col className="d-flex flex-column mb-3">
										<b> Sample size: {scale}</b>
										<RangeStepInput
											min={0.5}
											max={10}
											value={scale}
											step={0.2}
											onChange={(e) => {
												setScale(e.target.value);
											}}
										/>
									</Col>
									<Col className="d-flex flex-column">
										<b> Zoom: {Number(`${imageScale}`)?.toFixed(2)}</b>
										<RangeStepInput
											min={0.1}
											max={3}
											value={imageScale}
											step={0.01}
											onChange={(e) => {
												setImageScale(e.target.value);
											}}
										/>
									</Col>
								</Col>
								<Col
									md={5}
									className="d-flex gap-3 align-items-center justify-content-center"
								>
									<Button
										variant="success"
										disabled={savingSamples}
										onClick={() => {
											if (assessments?.length > 0 && assessment_id > 0)
												handleSaveSample();
										}}
										className="d-flex align-items-center gap-1"
									>
										<FaSave />
										{savingSamples ? (
											<Col className="d-flex justify-content-center align-items-center gap-1">
												Saving...{" "}
												<Spinner
													size="sm"
													animation="border"
													variant="light"
												/>
											</Col>
										) : (
											"Save"
										)}
									</Button>
								</Col>
							</Col>
						</S.Header>
					</Col>

					<Col
						className="d-flex"
						style={{ maxHeight: "36px" }}
					>
						<Button
							size="sm"
							onClick={() => {
								if (assessments?.length > 0 && assessment_id > 0)
									fileInputRef.current.click();
							}}
							className="d-flex align-items-center gap-1"
						>
							<FaImage />
							Change Image
						</Button>

						<Col className="mx-3 mt-1">
							<Form.Check
								label={"Move samples with the image"}
								id="lock-samples"
								checked={lockSamples}
								onChange={(e) => setLockSamples(e.target.checked)}
							/>
						</Col>
					</Col>
					<S.KonvaContainer id="konva-container">
						{renderKonva}
					</S.KonvaContainer>
				</>
			)}
		</Col>
	);
}

export default Assessment;
