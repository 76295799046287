import React, { useEffect, useMemo, useState } from "react";
import { Button, Col, Form, Spinner } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";

import api from "../../services/api";

import { DataCard } from "../../components/DataVisualization/styles";

import {
	ContainerTable,
	CustomTable,
	DataTitle,
	TD,
} from "../../components/ApplicationMap/styles";

const FieldItems = () => {
	const location = useLocation();
	const history = useHistory();

	const [fieldMap, setFieldMap] = useState();
	const [fieldItems, setFieldItems] = useState([]);
	const [loading, setLoading] = useState(false);
	const [saving, setSaving] = useState(false);

	const pasteTreatments = async () => {
		const cb = navigator.clipboard;

		if (cb && window.isSecureContext) {
			const cbText = await cb.readText();
			let lines = cbText.split("\n");

			if (lines[0]?.indexOf("Tratamento") !== -1) lines.shift();

			const matrix = lines
				.map((l) => l.split("\t"))
				.filter((l) => !l.some((value) => !value?.trim()));

			if (matrix.length !== fieldMap.fm_n_treatments) {
				toast.error(
					`Make sure to copy all treatments. The number of treatments must be ${fieldMap.fm_n_treatments}`
				);
			} else {
				if (matrix[0]?.length !== fieldMap.fm_n_repetitions + 1) {
					toast.error(
						`Make sure to copy all repetitions. The number of repetitions must be ${fieldMap.fm_n_repetitions}`
					);
				} else {
					const newFieldItems = [...fieldItems].map((fi) => {
						const reference = matrix.find((el) =>
							el.some((value) => value.indexOf(fi.fi_label) !== -1)
						);

						if (!!reference) {
							fi.fi_treatment = Number(reference[0]);
						}

						return fi;
					});

					setFieldItems(newFieldItems);
				}
			}
		}
	};

	const saveChanges = async () => {
		setSaving(true);
		try {
			const cheatsheet = Array.from({ length: fieldMap.fm_n_repetitions }, () =>
				Array.from(
					{ length: fieldMap.fm_n_treatments },
					(_, idxTreat) => idxTreat + 1
				)
			)
				.flat()
				.sort((a, b) => a - b);

			const items = fieldItems.map((f) => f.fi_treatment).sort((a, b) => a - b);

			const valid = JSON.stringify(cheatsheet) === JSON.stringify(items);

			if (!valid)
				toast.error(
					<span>
						Error! Make sure you correctly fill in the field map, respecting the
						number of <b>treatments</b> and <b>repetitions</b>.
					</span>,
					{
						autoClose: 6000,
					}
				);
			else {
				await api.put(`/field-map/${fieldMap.fm_id}`, fieldItems);

				toast.success("Changes successfully saved!");
			}
		} catch (error) {
			toast.error("There was an error while saving changes.");
		}
		setSaving(false);
	};

	const tableContentRenderAppMap = useMemo(() => {
		const rows = [];
		let columns = [];

		fieldItems?.forEach((element, index) => {
			if (index % fieldMap.fm_n_treatments === 0) {
				rows.push(<tr>{columns}</tr>);
				columns = [];
			}
			columns.push(
				<TD key={element.fi_id}>
					<Col>
						<div> {element.fi_label} </div>
						<Form.Control
							size="sm"
							className="mt-1 text-center"
							name="table-input-108"
							onChange={(e) => {
								setFieldItems((s) =>
									s.map((item) =>
										item.fi_id !== element.fi_id
											? item
											: {
													...item,
													fi_treatment: Number(e.target.value),
											  }
									)
								);
							}}
							value={element.fi_treatment}
						/>
					</Col>
				</TD>
			);
		});
		if (columns.length) {
			rows.push(<tr>{columns}</tr>);
		}

		return rows;
	}, [fieldItems, fieldMap]);

	useEffect(() => {
		setFieldMap(location.state);
	}, [location.state]);

	useEffect(() => {
		if (fieldMap?.fm_id && fieldMap.fm_id > 0) {
			const fetchItems = async () => {
				setLoading(true);
				try {
					const { data } = await api.get(`/field-map/detail/${fieldMap.fm_id}`);
					setFieldItems(data);
				} catch (error) {
					toast.error("There was an error while loading field map items.");
				}
				setLoading(false);
			};
			fetchItems();
		}
	}, [fieldMap]);

	if (!fieldMap) return null;

	if (loading)
		return (
			<Col className="w-100 h-100 d-flex justify-content-center align-items-center">
				<Spinner variant="success" />
			</Col>
		);

	return (
		<Col>
			<DataCard className="p-3 justify-content-center align-items-center gap-3">
				<DataTitle>{fieldMap.fm_name}</DataTitle>
				<ContainerTable>
					<CustomTable>
						<tbody>
							<tr>{tableContentRenderAppMap}</tr>
						</tbody>
					</CustomTable>
				</ContainerTable>
			</DataCard>
			<Col className="d-flex justify-content-end align-items-center gap-3 mx-3">
				{window.isSecureContext ? (
					<Button
						disabled={saving}
						onClick={pasteTreatments}
					>
						Paste from clipboard
					</Button>
				) : null}
				<Button
					disabled={saving}
					variant="secondary"
					onClick={() => {
						history.goBack();
					}}
				>
					{"Go back"}
				</Button>
				<Button
					disabled={saving}
					variant="success"
					onClick={saveChanges}
				>
					{saving ? "Saving..." : "Save changes"}
				</Button>
			</Col>
		</Col>
	);
};

export default FieldItems;
