import React, { useMemo, useState } from "react";
import { Button, Col, Form, Modal } from "react-bootstrap";

const ModalNewUser = ({ show, close, handleSave: submit }) => {
  const [savingUser, setSavingUser] = useState(false);
  const [data, setData] = useState({
    login: "",
    password: "",
    confirmPassword: "",
  });

  const handleSave = async () => {
    setSavingUser(true);
    await submit(data);
    setSavingUser(false);
  };

  const changeField = (campo, valor) =>
    setData((s) => ({ ...s, [campo]: valor }));

  const passwordDontMatch = useMemo(() => {
    const valid = [];

    // Check password match
    valid.push(data.password?.trim() === data.confirmPassword?.trim());

    return valid.some((v) => !v);
  }, [data]);

  const invalidData = useMemo(() => {
    const valid = [];

    // Check against emptyness
    valid.push(!!data.login?.trim());
    valid.push(!!data.password?.trim());
    valid.push(!!data.confirmPassword?.trim());

    // Check password match
    valid.push(!passwordDontMatch);

    return valid.some((v) => !v);
  }, [data, passwordDontMatch]);


  return (
    <Modal centered show={show} onHide={close}>
      <Modal.Header closeButton>
        <Modal.Title>
          New User
        </Modal.Title>
      </Modal.Header>
      <Form onSubmit={(e) => {
        e.preventDefault()

      }}>

        <Modal.Body>
          <Form.Group as={Col} className='mb-3'>
            <Form.Label>Login</Form.Label>
            <Form.Control
              required
              autoComplete={false}
              onChange={(e) => {
                changeField("login", e.target.value);
              }}
              value={data.login}
            />

          </Form.Group>
          <Form.Group as={Col} className='mb-3'>
            <Form.Label>Password</Form.Label>
            <Form.Control
              required
              autoComplete={false}
              type="password"
              onChange={(e) => {
                changeField("password", e.target.value);
              }}
              value={data.password}
            />

          </Form.Group>
          <Form.Group as={Col} className='mb-3'>
            <Form.Label>Confirm Password</Form.Label>
            <Form.Control
              required
              isInvalid={passwordDontMatch && data.confirmPassword.length > 0}
              autoComplete={false}
              type="password"
              onChange={(e) => {
                changeField("confirmPassword", e.target.value);
              }}
              value={data.confirmPassword}
            />
            <Form.Control.Feedback type="invalid">
              Password doesn't match!
            </Form.Control.Feedback>
          </Form.Group>

        </Modal.Body>
        <Modal.Footer>

          <Button
            variant='success'
            disabled={invalidData || savingUser}
            onClick={() =>
              handleSave({
                login: data.login,
                password: data.password,
              })
            }
          >
            {savingUser ? "Saving..." : "Save"}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>

  );
};

export default ModalNewUser;
