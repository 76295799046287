import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal } from "react-bootstrap";
import { toast } from "react-toastify";

import { assessmentTypeList } from "../../../TrialAssessment/helper";

const ModalFormTrial = ({
	exibir,
	fechar,
	editing,
	training,
	oldInfo,
	savingTrial,
	handleSave,
	hasFieldMap,
}) => {
	const [form, setForm] = useState({
		name: "",
		target: "",
		season: "",
		treatments_count: 0,
		repetitions_count: 0,
		sowing_date: new Date(),
	});

	const changeForm = (field, value) =>
		setForm((s) => ({ ...s, [field]: value }));

	useEffect(() => {
		if (editing) setForm((s) => ({ ...s, ...oldInfo }));
	}, [oldInfo, editing]);

	const submitForm = (e) => {
		e.preventDefault();

		// Treatment and repetition cannot be less than 1
		if (
			[form.repetitions_count, form.treatments_count].some(
				(item) => !Number.isFinite(Number(item)) || item < 1
			)
		) {
			toast.warning("Both treatment and repetition must be at least 1");
			return;
		}

		handleSave(form);
	};

	return (
		<Modal
			show={exibir}
			onHide={fechar}
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title>{editing ? "Edit trial" : "New trial"}</Modal.Title>
			</Modal.Header>
			<Form onSubmit={submitForm}>
				<Modal.Body>
					<Form.Group
						as={Col}
						md={12}
						className="mb-3"
					>
						<Form.Label>Name</Form.Label>
						<Form.Control
							required
							onChange={(e) => changeForm("name", e.target.value)}
							value={form.name}
						/>
					</Form.Group>
					{training ? null : (
						<Form.Group
							as={Col}
							md={12}
							className="mb-3"
						>
							<Form.Label>Crop</Form.Label>
							<Form.Control
								required
								onChange={(e) => changeForm("crop", e.target.value)}
								value={form.crop}
							/>
						</Form.Group>
					)}
					{!training ? null : (
						<Form.Group
							as={Col}
							md={12}
							className="mb-3"
						>
							<Form.Label>Target</Form.Label>
							<Form.Select
								required
								onChange={(e) => changeForm("target", e.target.value)}
								value={form.target}
							>
								{assessmentTypeList
									.filter((type) => training === (type.value !== "target_spot"))
									.map((type) => (
										<option
											key={type.value}
											value={type.value}
										>
											{type.name}
										</option>
									))}
							</Form.Select>
						</Form.Group>
					)}

					{training ? null : (
						<Form.Group
							as={Col}
							md={12}
							className="mb-3"
						>
							<Form.Label>Season</Form.Label>
							<Form.Control
								required
								onChange={(e) => changeForm("season", e.target.value)}
								value={form.season}
							/>
						</Form.Group>
					)}

					<Form.Group
						as={Col}
						md={12}
						className="mb-3"
					>
						<Form.Label>Treatments</Form.Label>
						<Form.Control
							required
							disabled={hasFieldMap}
							type="number"
							onChange={(e) => changeForm("treatments_count", +e.target.value)}
							value={form.treatments_count}
						/>
					</Form.Group>

					<Form.Group
						as={Col}
						md={12}
						className="mb-3"
					>
						<Form.Label>Repetition</Form.Label>
						<Form.Control
							required
							type="number"
							disabled={hasFieldMap}
							onChange={(e) => changeForm("repetitions_count", +e.target.value)}
							value={form.repetitions_count}
						/>
					</Form.Group>

					{training ? null : (
						<Form.Group
							as={Col}
							md={12}
							className="mb-3"
						>
							<Form.Label>Date</Form.Label>
							<Form.Control
								required
								type="date"
								onChange={(e) => changeForm("sowing_date", e.target.value)}
								value={form.sowing_date}
								custom
							/>
						</Form.Group>
					)}
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="secondary"
						onClick={fechar}
					>
						Cancel
					</Button>
					<Button
						variant="success"
						type="submit"
					>
						{savingTrial ? "Saving..." : "Save"}
					</Button>
				</Modal.Footer>
			</Form>
		</Modal>
	);
};

export default ModalFormTrial;
