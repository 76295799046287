import React from "react";
import {
	Document,
	Page,
	Text,
	View,
	StyleSheet,
	Image,
	Font,
} from "@react-pdf/renderer";
import { capilizeCharAt0, formatDate } from "../../util";

Font.register({
	family: "Ubuntu",
	fonts: [
		{
			src: "https://fonts.gstatic.com/s/ubuntu/v15/4iCv6KVjbNBYlgoC1CzTtw.ttf",
			fontWeight: 300,
		},
		{
			src: "https://fonts.gstatic.com/s/ubuntu/v15/4iCs6KVjbNBYlgo6eA.ttf",
			fontWeight: 400,
		},
		{
			src: "https://fonts.gstatic.com/s/ubuntu/v15/4iCv6KVjbNBYlgoCjC3Ttw.ttf",
			fontWeight: 500,
		},
		{
			src: "https://fonts.gstatic.com/s/ubuntu/v15/4iCv6KVjbNBYlgoCxCvTtw.ttf",
			fontWeight: 700,
		},
	],
});

// Create styles
const styles = StyleSheet.create({
	page: {
		flexDirection: "row",
		backgroundColor: "white",
		fontFamily: "Ubuntu",
	},
	section: {
		margin: 10,
		padding: 10,
		flexGrow: 1,
	},
	title: {
		width: "100%",
		textAlign: "center",
		fontSize: 15,
		marginBottom: 24,
		fontWeight: "bold",
	},
	headerText: {
		width: "100%",
		textAlign: "left",
		fontSize: 13,
		marginBottom: 8,
	},
	bold: {
		fontWeight: "bold",
	},
	containerChartAnalysis: {
		width: "100%",
		height: "262px",
		display: "flex",
		alignItems: "center",
		flexDirection: "row",
		marginVertical: 12,
	},
	containerEstimatedSeverity: {
		width: "100%",
		height: "332px",
		display: "flex",
		alignItems: "center",
		flexDirection: "column",
		justifyContent: "flex-start",
	},
	estimatedSeveriy: {
		width: "70%",
		height: "70%",
		objectFit: "contain",
	},
	containerChart: {
		width: "73%",
		height: "100%",
		display: "flex",
		alignItems: "center",
		flexDirection: "column",
	},
	chartConfigContainer: {
		width: "100%",
		height: "30%",
		display: "flex",
		alignItems: "center",
		flexDirection: "column",
	},
	chartImage: {
		width: "100%",
		height: "70%",
		objectFit: "contain",
	},
	containerAnalysis: {
		width: "27%",
		height: "100%",
		flex: 1,
		display: "flex",
		alignItems: "center",
		flexDirection: "column",
		justifyContent: "flex-start",
	},
	textConfig: {
		width: "100%",
		textAlign: "left",
		fontSize: 10,
		marginBottom: 4,
	},
	textAnalysis: {
		width: "100%",
		textAlign: "left",
		fontSize: 10,
		marginBottom: 12,
	},
	separator: {
		marginVertical: 12,
		width: "100%",
		height: 1,
		backgroundColor: "lightgrey",
	},

	pageNumber: {
		position: "absolute",
		fontSize: 12,
		bottom: 30,
		left: 0,
		right: 0,
		textAlign: "center",
		color: "grey",
	},
});

const classificationMap = {
	scottknott: "ScottKnott",
	duncan: "Duncan",
	tukey: "Tukey",
};

const sortMap = {
	treatment: "Treatment",
	higher_value: "Highest to lowest value",
};

// Create Document Component
const PDFSeverity = ({
	trial,
	assessment,
	analysis,
	chartImage,
	estimatedSeverityImage,
	sortBy,
	classification,
}) => (
	<Document>
		<Page
			size="A4"
			style={styles.page}
		>
			<View style={styles.section}>
				<Text style={styles.title}>
					{trial?.name} - {capilizeCharAt0(assessment.type)} (
					{formatDate(assessment.date)})
				</Text>
				<Text style={styles.headerText}>
					Crop: <Text style={styles.bold}>{trial?.crop}</Text>
				</Text>
				<Text style={styles.headerText}>
					Season: <Text style={styles.bold}>{trial?.season}</Text>
				</Text>

				<View style={styles.separator} />

				<View style={styles.containerChartAnalysis}>
					<View style={styles.containerChart}>
						<View style={styles.chartConfigContainer}>
							<Text
								style={{
									...styles.headerText,
									fontWeight: "bold",
								}}
							>
								Estimated {capilizeCharAt0(assessment.type)}
							</Text>
							<Text style={styles.textConfig}>
								Sorted by: <Text style={styles.bold}>{sortMap[sortBy]}</Text>
							</Text>
							{classification?.trim() ? (
								<Text style={styles.textConfig}>
									Classification:{" "}
									<Text style={styles.bold}>
										{classificationMap[classification] || ""}
									</Text>
								</Text>
							) : null}
						</View>
						<Image
							style={styles.chartImage}
							src={chartImage}
						/>
					</View>

					<View style={styles.containerAnalysis}>
						<Text
							style={{
								...styles.headerText,
								textAlign: "center",
								fontWeight: "bold",
							}}
						>
							Analysis
						</Text>
						<Text style={styles.textAnalysis}>
							Alpha: <Text style={styles.bold}>{analysis.alpha}</Text>
						</Text>
						<Text style={styles.textAnalysis}>
							CV: <Text style={styles.bold}>{analysis.cv}</Text>
						</Text>
						<Text style={styles.textAnalysis}>
							DMSTukey: <Text style={styles.bold}>{analysis.dms}</Text>
						</Text>
						<Text style={styles.textAnalysis}>
							GL: <Text style={styles.bold}>{analysis.gl}</Text>
						</Text>
						<Text style={styles.textAnalysis}>
							Homogeneity:{" "}
							<Text style={styles.bold}>{analysis.homogeneidade}</Text>
						</Text>
						<Text style={styles.textAnalysis}>
							Normality: <Text style={styles.bold}>{analysis.normalidade}</Text>
						</Text>
						<Text style={styles.textAnalysis}>
							P Value: <Text style={styles.bold}>{analysis.p_valor}</Text>
						</Text>
						<Text style={styles.textAnalysis}>
							QMres: <Text style={styles.bold}>{analysis.qmres}</Text>
						</Text>
					</View>
				</View>

				<View style={styles.containerEstimatedSeverity}>
					<Text
						style={{
							...styles.headerText,
							fontWeight: "bold",
						}}
					>
						Application Map
					</Text>
					<Image
						style={styles.estimatedSeveriy}
						src={estimatedSeverityImage}
					/>
				</View>
			</View>

			<Text
				style={styles.pageNumber}
				render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
				fixed
			/>
		</Page>
	</Document>
);

export default PDFSeverity;
