import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 8px; 
  border: 1px solid #c1c1c1;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
 
`;

export const SpinnerLoading = styled.div` 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  div div div {
    background: #ff4143 !important; 
  }
`;