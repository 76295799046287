import React from "react";
import * as renderer from "@react-pdf/renderer";
import { v4 } from "uuid";

const styles = renderer.StyleSheet.create({
	table: {
		width: "100%",
		display: "flex",
		flexDirection: "column",
		marginBottom: 12,
	},
	tr: {
		display: "flex",
		flexDirection: "row",
	},
	th: {
		padding: 4,
		fontSize: 10,
		fontWeight: "bold",
		backgroundColor: "#e0e0e0",
		color: "#2c2c2c",
		borderWidth: 1,
		borderColor: "#dee2e6",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		textAlign: "center",
		fontFamily: "Ubuntu",
		flexWrap: "wrap",
	},
	td: {
		padding: 4,
		fontSize: 10,
		borderColor: "#dee2e6",
		borderWidth: 1,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		textAlign: "center",
		fontFamily: "Ubuntu",
		flexWrap: "wrap",
	},
});

const TR = ({ children }) => (
	<renderer.View
		wrap={false}
		style={styles.tr}
	>
		{children}
	</renderer.View>
);

const TH = ({ children, width }) => (
	<renderer.Text style={{ ...styles.th, width }}>{children}</renderer.Text>
);

const TD = ({ children, width, noBorder, noHeader }) => (
	<renderer.Text
		style={{
			...styles.td,
			width,
			...(noBorder ? { borderColor: "white" } : {}),
			...(noHeader ? { textAlign: "center" } : {}),
		}}
	>
		{children}
	</renderer.Text>
);

const ItemsTable = ({ data, widths, noBorder, noHeader }) => (
	<renderer.View
		wrap
		style={styles.table}
	>
		{data.map((linha, idxLin) => (
			<TR key={v4() + "linha" + idxLin}>
				{linha.map((celula, idxCel) =>
					idxLin === 0 && !noHeader ? (
						<TH
							key={v4() + "celula" + idxLin + "-" + idxCel}
							width={
								widths ? widths[idxCel] : Math.floor(100 / linha.length) + "%"
							}
						>
							{celula}
						</TH>
					) : (
						<TD
							key={v4() + "celula" + idxLin + "-" + idxCel}
							width={
								widths ? widths[idxCel] : Math.floor(100 / linha.length) + "%"
							}
							noBorder={noBorder}
							noHeader={noHeader}
						>
							{celula}
						</TD>
					)
				)}
			</TR>
		))}
	</renderer.View>
);

export default ItemsTable;
